import { 
  IOpenJob,
  IOpenJobsData,
  IOpenJobsFilterOptions,
  IOpenJobsSelectedFilterOptions,
  ISearchableOpenJobsData 
} from "./openJobs.interfaces";
import moment from "moment";
import { boolean } from "yup/lib/locale";
import LocalStorageService from "services/LocalStorageService";
import { AppConfig } from "ui-constants";
import { OPEN_JOBS_POSTED_DATE_FILTER_DROPDOWN_CLASSNAMES } from "./DateRangePickerTabs/dateRangePickerTabs.constants";
import { FastField } from "formik";
import { RANGES } from "./openJobs.constants";

const getJobLocationLabel = (jobLocation: IOpenJob["jobLocation"]) => {
  if (!jobLocation) return "";
  const city = jobLocation?.city;
  const state = jobLocation?.state;
  let label = "";
  if (city && state) {
    label = `${city}, ${state}`;
  } else if (city && !state) {
    label = `${city}`;
  } else if (!city && state) {
    label = `${state}`;
  }
  return label;
};

export const getOpenJobsAvailableFilterLocationOptions = (openJobsData: IOpenJobsData) =>  {
  const availableJobLocations: { label: string, jobLocation: IOpenJob["jobLocation"] }[] = [];
  openJobsData.forEach((job) => {
    if (job?.jobLocation) {
      let label = getJobLocationLabel(job?.jobLocation);
      if (label) {
        const isAlreadyInList = Boolean(availableJobLocations.find((availableJobLocation) => availableJobLocation.label === label));
        if (!isAlreadyInList) availableJobLocations.push({ label, jobLocation: job?.jobLocation });
      }
    }
  });

  availableJobLocations.sort((a, b) => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  });

  return availableJobLocations;
};

export const getOpenJobsLocationsFilter = (jobLocations) =>{
  const availableJobLocations: { label: string, jobLocation: IOpenJob["jobLocation"] }[] = [];
  jobLocations?.forEach((jobLocation) => {
    if (jobLocation) {
      let label = getJobLocationLabel(jobLocation);
      if (label) {
        const isAlreadyInList = Boolean(availableJobLocations?.find((availableJobLocation) => availableJobLocation?.label === label));
        if (!isAlreadyInList) availableJobLocations.push({ label, jobLocation: jobLocation });
      }
    }
  });

  availableJobLocations.sort((a, b) => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  });

  return availableJobLocations;
}

export const setOrDeleteParam = (searchParams, param, value) => {
  if (value) {
    searchParams.set(param, JSON.stringify(value));
  } else {
    searchParams.delete(param);
  }
};


export const getSearchableOpenJobsData = (openJobsData: IOpenJobsData) => {
  const searchableOpenJobsData: ISearchableOpenJobsData = openJobsData.map((openJob) => {
    const jobId = openJob?.requestId || "";
    const jobTitle = openJob?.title || "";
    const jobDescription = openJob?.description || "";
    const jobLocation = getJobLocationLabel(openJob?.jobLocation) || "";
    const lowerCaseSearchableText = `${jobId} ${jobTitle} ${jobDescription} ${jobLocation}`.toLowerCase();

    return { ...openJob, lowerCaseSearchableText };
  }) || [];

  return searchableOpenJobsData;
};

export const prepareSearchableAndFilterableOpenJobsData = (openJobsData: IOpenJobsData) => {
  const searchableOpenJobs = getSearchableOpenJobsData(openJobsData);
  const availableJobLocations = getOpenJobsAvailableFilterLocationOptions(openJobsData);
  const filterOptions: IOpenJobsFilterOptions = {
    jobType: ["Relevant Jobs"],
    jobLocations: availableJobLocations,
  };

  return { searchableOpenJobs, filterOptions };
};

export const isAnyOpenJobsFilterSelected = ({ 
  searchKeyword,
  selectedOpenJobsFilterOptions
}: {
  searchKeyword?: string,
  selectedOpenJobsFilterOptions: IOpenJobsSelectedFilterOptions
}) => {
  return Boolean(
    searchKeyword
    || selectedOpenJobsFilterOptions?.jobLocations?.length
    || selectedOpenJobsFilterOptions?.jobType?.length
    || selectedOpenJobsFilterOptions?.dateRange?.startDate
    || selectedOpenJobsFilterOptions?.dateRange?.endDate
  );
};

// used to render card 
export const getPaginatedCardData = ({ pageNumber, cardData, offset }: { pageNumber?: number, offset?: number, cardData: Array<any> }) => {
  if (cardData?.length === 0 || cardData?.length < 10) {
    return cardData.slice(0, cardData?.length);
  }

  if (Number(offset)) {
    return cardData.slice(offset - 1, offset + 10);
  } else if (Number(pageNumber)) {
    return cardData.slice(pageNumber - 1, pageNumber + 9);
  } else {
    return cardData;
  }
};

export const getOpenJobsDataAfterApplyingSearchAndFilter = ({
  searchedKeyword,
  searchableOpenJobsData,
  selectedOpenJobsFilterOptions,
}: {
  searchableOpenJobsData: ISearchableOpenJobsData,
  selectedOpenJobsFilterOptions: IOpenJobsSelectedFilterOptions,
  searchedKeyword?: string,
}) => {
  const filteredOpenJobsData = searchableOpenJobsData.filter((openJob) => {
    const {
      jobLocation,
      requestedOn,
      isRelevant,
      lowerCaseSearchableText,
      jobCategory,
    } = openJob;
    const isKeywordMatch = searchedKeyword && searchedKeyword!=='undefined'
      /** when search is applied, show jobs that match the search keyword */
      ? lowerCaseSearchableText?.includes(searchedKeyword) || false
      /** when search is not applied, show all jobs */
      : true;

    const isRelevantJobFilter = selectedOpenJobsFilterOptions?.jobType?.length 
      /** when filter is applied, show only relevant jobs */
      ? isRelevant || false 
      /** when filter is not applied, show all jobs */
      : true;

    const isJobLocationMatchFilter = selectedOpenJobsFilterOptions?.jobLocations?.length
      /** when filter is applied, show only jobs that have exact match of city & state */
      ? selectedOpenJobsFilterOptions?.jobLocations?.find((jobLocationObj) => {
        const isCityMatch = jobLocationObj?.jobLocation?.city?.toLowerCase() === jobLocation?.city?.toLowerCase();
        const isStateMatch = jobLocationObj?.jobLocation?.state?.toLowerCase() === jobLocation?.state?.toLowerCase();
        return isCityMatch && isStateMatch;
      })
      /** when filter is not applied, show all jobs */
      : true;

    let isJobPostedDateMatchFilter;
    const DATE_FORMAT = "YYYY-MM-DD";
    const compareDate = moment(requestedOn, DATE_FORMAT);
    const selectedStartDate = selectedOpenJobsFilterOptions?.dateRange?.startDate
    const selectedEndDate = selectedOpenJobsFilterOptions?.dateRange?.endDate
    if (selectedStartDate && selectedEndDate) {
      /** when filter is applied, 
       * and both start and end dates are selected,
       * show only jobs that are posted between the selected dates */
      const startDate = moment(selectedStartDate, DATE_FORMAT);
      const endDate = moment(selectedEndDate, DATE_FORMAT);
      isJobPostedDateMatchFilter = compareDate.isBetween(startDate, endDate, 'days', '[]')
    } else if (selectedStartDate && !selectedEndDate) {
      /** when filter is applied,
       * and only start date is selected,
       * show only jobs that are posted on or after the selected start date */
      const startDate = moment(selectedStartDate, DATE_FORMAT);
      isJobPostedDateMatchFilter = compareDate.isSameOrAfter(startDate, 'days')
    } else if (!selectedStartDate && selectedEndDate) {
      /** when filter is applied,
       * and only end date is selected,
       * show only jobs that are posted on or before the selected end date */
      const endDate = moment(selectedEndDate, DATE_FORMAT);
      isJobPostedDateMatchFilter = compareDate.isSameOrBefore(endDate, 'days')
    } else {
      isJobPostedDateMatchFilter = true;
    }
    
    //added jobcategory filter, it returns true if the applied job category is undefined or equal to "All" or equal to current selected category
    let jobCategoryMatch = jobCategory === selectedOpenJobsFilterOptions?.jobCategory ||  !selectedOpenJobsFilterOptions?.jobCategory || selectedOpenJobsFilterOptions?.jobCategory==='All' ? true : false;

    if(!jobCategory && selectedOpenJobsFilterOptions?.jobCategory==="Others"){
      jobCategoryMatch = true;
    }

    /** return true if any of the filters match */
    return (
      // search
      isKeywordMatch 
      // filters
      && isJobLocationMatchFilter 
      && isJobPostedDateMatchFilter
      && isRelevantJobFilter
      && jobCategoryMatch
    );
  });
  return filteredOpenJobsData;
};

export const updateOpenJobsLocalStorageKeys = ({
  remove,
  value,
}: {
  remove?: boolean,
  value?: {
    isFromOpenJobsDetailsPage: boolean,
    prevOpenJobSearchKeyword: string,
    prevSelectedOpenJobsFilterOptions: IOpenJobsSelectedFilterOptions,
  },
}) => {
  if (remove && !value) {
    LocalStorageService.remove(AppConfig.STORAGE_KEYS.prevOpenJobSearchKeyword);
    LocalStorageService.remove(AppConfig.STORAGE_KEYS.isFromOpenJobsDetailsPage);
    LocalStorageService.remove(AppConfig.STORAGE_KEYS.prevSelectedOpenJobsFilterOptions);
  } else {
    LocalStorageService.set(
      AppConfig.STORAGE_KEYS.isFromOpenJobsDetailsPage, 
      value?.isFromOpenJobsDetailsPage
    );
    LocalStorageService.set(
      AppConfig.STORAGE_KEYS.prevOpenJobSearchKeyword, 
      value?.prevOpenJobSearchKeyword
    );
    LocalStorageService.set(
      AppConfig.STORAGE_KEYS.prevSelectedOpenJobsFilterOptions, 
      value?.prevSelectedOpenJobsFilterOptions
    );
  }
};

// remove all date pickers from the DOM
export const removeOpenJobsDateDropdownsInDOM = (document: Document) => {
  document.querySelectorAll(
    `.${OPEN_JOBS_POSTED_DATE_FILTER_DROPDOWN_CLASSNAMES.START_DATE}`
  ).forEach(el => el.remove());
  document.querySelectorAll(
    `.${OPEN_JOBS_POSTED_DATE_FILTER_DROPDOWN_CLASSNAMES.END_DATE}`
  ).forEach(el => el.remove());
};

export const addMilliseconds = (date, milliseconds) => {
  return new Date(date.getTime() + milliseconds);
}

export const getTurnoverRange = (turnover: number) =>{
  for (const range of RANGES) {
    if (turnover <= range.max) {
      return range.label;
    }
  }
}

