import React, { useEffect, useState } from "react";
import {  Empty  } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/Button/Button";
import { CardTag } from "components/Card";
import InputWithIcon from "components/Input/InputBoxWithEdit/InputWithIcon/input";
import {
  capabilitiesOnboardingGet,
  capabilitiesOnboardingPost,
  capabilitiesUpdate,
} from "reducers/capabilities";
import {
  profileLayoutError,
  resetProfileCompletionStatus,
  toggleIsPageEdited,
} from "reducers/profileCreation";
import { DropdownData, stateGeoDbData, Work } from "constants/mockData";
import { Modal, Input, Progress, Spin } from "antd";
import { useHistory } from "react-router-dom";
import ProfileCreationButtons from "components/ProfileCreationButtons";
import ProfileInputHeading from "Profile/shared/Heading/Heading";
import DropdownWithHeader from "components/Dropdown/DropdownWithHeader/DropdownWithHeader";
import InputBoxForEdit from "components/Input/InputBoxWithEdit/inputBox";
import NewUploadImage from "components/NewUploadImage/NewUploadImage";
import ManufacturingCapabilities from "./ManufacturingCapabilities";
import { searchCountry, searchState } from "reducers/locations";
import { Label } from "components/Label";
import { LogoImage } from "components/Image";
import {
  fileUploadPost,
  fileUploadPostWithPercentage,
  fileUploadPermanent,
} from "reducers/fileUpload";
import TwoInputField from "Profile/shared/Input/InputWithAddButton/TwoInputField";
import CardLocationTag from "components/Card/CardLocation/Card";
import notificationFunc from "components/Notification";
import { getGstn } from "constants/auth";
import FormField from "Profile/shared/FormField/FormField";
import { handleScrollToView } from "shared/handlers/form.handler";
import { updateProfileFlagToTrue } from "shared/handlers/updateProfiileFlag.handler";
import {
  MultiSelectDropdown,
  ContentInputs,
  DropdownWithTagDiv,
  TagContainer,
  FormWrapper,
  InputCardIconWrapper,
  TextAreaWrapper,
  ManufactureTextAreaWrapper,
  LocationContentWrapper,
  TextAreaDiv,
  InputFlexWrapper,
  CardWrapper,
  ManufacturingCardWrapper,
  InputDivWrap,
  InputWrap,
  LocationServedDiv,
  LocationWrapper,
  FlexColumn,
  KeyCustomer,
  StateServedTagContainer,
  Div,
  WarningMsg,
  UploadIcon,
  TextDiv,
  ColumnDiv,
  AddBttn,
  CertificatesWrapper,
  TextAreaView,
  BttnContainer,
  Bttn,
  AddManuallyBttn,
  KeyCustomerDescContainer,
  LineHider,
  AdditionalInfoWrapper,
  DisplayCertOnRespScreen,
  CertificateBttns,
  KeyCustomerFileRespView,
  CaptionButton,
  CapabilitiesCustomReactQuillEditorWrapper,
} from "./CapabilitiesNew.styles";
import { reactQuillCustomStyles } from "components/CustomReactQuillEditor/reactQuillEditor.styles";
import { getOemTags } from "reducers/oemTagsLibrary";
import { CertOrOem } from "./components/CertOrOem";
import { getTechCerts } from "reducers/techCertificatesLibrary";
import { setIsLoading } from "reducers/auth";
import { ButtonWrapper } from "Profile/Profile.styles";
import CapabilitiesModal from "./components/Modal";
import { checkForMobileScreen } from "shared/handlers/getUserScreenSize.handler";
import { AddInfoModal, ModalDiv } from "./components/Modal/Modal.styles";
import { URL_REGEX } from "constants/regex";
import { VerificationStatus } from "Profile/enums";
import { HEAP_DATA_ATTRIBUTES, HEAP_DATA_TRACKING_ID } from "constants/heapAnalytics";
import { Box,
  Typography,
  Flex,
  Button as VZuiButton, 
  ManufCapabilitiesTable,
} from "venwiz-ui";
import { ButtonProps } from "venwiz-ui/dist/esm/components/atoms/Button/Button.interface";
import { DropdownWrapper } from "Profile/Experience/Experience.styles";
import { BasicDropdown } from "components/Dropdown";
import InputWithHeaderForEdit from "components/Input/InputBoxWithEdit/inputHeader";
import { IoClose } from "react-icons/io5";
import AntdToast from "components/Toast/AntdToast";
import { getUserDetails } from "shared/handlers/getUserLoginStatus";
import { heapTrackTrigger } from "shared/handlers/heapAnalytics.handler";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { colors } from "Theme";
import LocalStorageService from "services/LocalStorageService";
import { AppConfig } from "ui-constants";
import { scrollToElementId } from "shared/handlers/scroll.handler";
import GalleryModal from "Profile/ViewProfile/components/GalleryModal/GalleryModal";
import { useMediaQuery } from "hooks/useMediaQuery.hook";
import { DEVICE } from "ui-constants/mediaQueries.constants";
import { getBytesFromMB, COMMON_FILE_TYPES } from "shared/handlers/file.handler";
import { FORM_DATA_KEY } from "Request/Constants/constants";
import { FileOperationsAction } from "shared/redux/file-operations";
import { 
  AddOrEditPlantModal,
  AddOrEditMachineModal,
} from "./components/PlantMachineDetails/modals";
import { 
  IMachine, 
  IPlantMachineDetail, 
  IMachineHandlerArgType,
  IManufCapabilitiesTableProps,
  IPlantHandlerArgType, 
} from "venwiz-ui/dist/esm/components/organism/ManufCapabilitiesTable/ManufCapabilitiesTable.interface";
import { RootState } from "reducers";
import { getNeo4JOEMTags } from "reducers/tags";
import { 
  modules, 
  BLOCK_TAG_NAME,
  VISUALLY_EMPTY_STRING, 
  DIV_WITH_EMPTY_SPACES_PATTERN,
  VISUALLY_EMPTY_STRING2,
} from "components/CustomReactQuillEditor/reqctQuill.constants";
import { 
  cleanFormatting,
  removeEmptyHTMLElementsFromString,
} from "components/CustomReactQuillEditor/reactQuill.helper";
import { getObjectsWithoutNameKey } from "Profile/helpers";


interface DisplayProps {
  hide?: boolean;
  onChange?: (e: any) => void;
}

const CapabilitiesNew = ({ onChange }: DisplayProps) => {
  const OTHER_CATEGORY = DropdownData?.galleryData?.[2]?.value as "Other";
  let isFirstError = false;
  const history = useHistory();
  const isScreenWidthLessThan640px = !useMediaQuery(DEVICE.sm);
  const [capabilityId, setCapabilityId] = useState("");
  const [typedVideoUrl, setTypedVideoUrl] = useState("");
  const capabilitiesState = useSelector((state: RootState) => state.capabilities);
  const { neo4jOemTags } = useSelector((state: RootState) => state.tags);
  const buttonState = useSelector((state: any) => state.profileCreation);
  const dispatch = useDispatch();
  const { capabilitiesData, loaded, loading: isCapabilitiesLoading } = capabilitiesState;
  let validateData = false;
  const [stateDropdownDetails, setStateDropdownDetails] = useState([]);
  //scope of work dropdown data
  const [sowDropdownDetails, sowStateDropdownDetails] = useState([]);
  const [modalData, setModalData] = useState({
    data: [],
    type: "",
    headerName: "",
  });
  const [showCertificates, setShowCertificates] = useState(false);
  const [modalOpenState, setModalOpenState] = useState(false);
  const [addManuallyBttnState, setAddManuallyBttnState] = useState(true);
  const [selectedWork, setSelectedWork] = useState([]);
  const [industriesServed, setIndustriesServed] = useState([]);
  const [otherWorkState, setOtherWorkState] = useState(false);
  const [otherDisciplineState, setOtherDisciplineState] = useState(false);
  const [selectedDiscipline, setSelectedDiscipline] = useState([]);
  const [serviceDesc, setServiceDesc] = useState("");
  const [keyCustomerDesc, setKeyCustomerDesc] = useState("");
  const [city, setCity] = useState("");
  const [capacityPlantLocation, setCapacityPlantLocation] = useState("");
  const [plantLocation, setPlantLocation] = useState([]);
  const [keyEquipment, setKeyEquipment] = useState([]);
  const [keyEquipmentName, setKeyEquipmentName] = useState("");
  const [keyEquipmentMake, setkeyEquipmentMake] = useState("");
  const [keyEquipmentCapacity, setkeyEquipmentCapacity] = useState("");
  const [saveState, setSaveState] = useState(false);
  const [productDesc, setProductDesc] = useState("");
  const [customerListFile, setCustomerListFile] = useState([]);
  const [tempDoc, setTempDoc] = useState([]);
  const [stateCardData, setStateCardData] = useState([]);
  const [countryCardData, setCountryCardData] = useState([]);
  const [locationServed, setLocationServed] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [countryList, setCountryList] = useState([]);
  const [selectedState, setSelectedState] = useState(undefined);
  const locationState = useSelector((state: any) => state.locations);
  const { cityLoading, stateLoading, countryLoading } = locationState;
  const [galleryData, setGalleryData] = useState<any>();
  const [isAddManufactureEdited, setIsAddManufactureEdited] = useState(false);
  const isOnboarding = window.location.href.includes("onboarding");
  const [isDataSaving, setIsDataSaving] = useState(false);
  const [natureOfWork, setNatureOfWork] = useState({
    selectedData: [],
    onChangeData: "",
  });
  const [selectedImageCategory, setSelectedImageCategory] = useState("");
  const [selectedVideoCategory, setSelectedVideoCategory] = useState("");
  const [isImageUploading, setIsImageUploading] = useState(false);

  const [isSaveChangesLoading, setIsSaveChangesLoading] = useState(false);

  const [discipline, setDiscipline] = useState({
    selectedData: [],
    onChangeData: "",
  });

  const [products, setProducts] = useState({
    selectedData: [],
    onChangeData: "",
  });

  const [plantDetails, setPlantDetails] = useState([]);
  const [keyEquipments, setKeyEquipments] = useState([]);
  const [plantMachineDetails, setPlantMachineDetails] = useState<
    IManufCapabilitiesTableProps["plantMachineDetails"]
  >([]);
  const [isPlantMachineDetailsPresent, setIsPlantMachineDetailsPresent] = useState(false);
  const profileCompletionData = useSelector(
    (state: any) => state.profileCreation
  ).profileCompletionStatus;

  const [vendorOemTags, setVendorOemTags] = useState([]);
  const [vendorTechCerts, setVendorTechCerts] = useState([]);
  const [oemOptions, setOemOptions] = useState([]);
  const [oemOptionsLibrary, setOemOptionsLibrary] = useState([]);
  const [techCertsOptions, setTechCertsOptions] = useState([]);
  const [techCertsOptionsLibrary, setTechCertsOptionsLibrary] = useState([]);

  const { NON_YOUTUBE_VIDEO_URL, YOUTUBE_VIDEO_URL } = URL_REGEX;
  let [video, setVideo] = useState([]);
  const [isVideoUrlVerified, setIsVideoUrlVerified] = useState(false);
  const [videoErrorState, setVideoErrorState] = useState(false);

  // text editor related values
  const Quill = ReactQuill.Quill 
  var Block = Quill.import('blots/block');
  Block.tagName = BLOCK_TAG_NAME;
  Quill.register(Block);

  const IS_SERVICE_DESC_ERROR = !serviceDesc 
    || serviceDesc === VISUALLY_EMPTY_STRING || serviceDesc === VISUALLY_EMPTY_STRING2
    || removeEmptyHTMLElementsFromString(serviceDesc) === "";

  // state for gallery modal
  const [isCurrentCaptionBeingEdited, setIsCurrentCaptionBeingEdited] = useState(false);
  const [viewGalleryModal, setViewGalleryModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showPulsingCaptionInputFieldBorder, setShowPulsingCaptionInputFieldBorder] = useState(false);
  const GALLERY_MODAL_DATA = (() => {
    let galleryModalData = [];
    if (galleryData?.length && video?.length) {
      galleryModalData = galleryData?.concat(video) || [];
    } else if (galleryData?.length && !video?.length) {
      galleryModalData = galleryData;
    } else if (!galleryData?.length && video?.length) {
      galleryModalData = video;
    }
    return galleryModalData;
  })();

  /** state for manufacturing capabilities table */
  const [showAddOrEditPlantModal, setShowAddOrEditPlantModal] = useState(false);
  const [showAddOrEditMachineModal, setShowAddOrEditMachineModal] = useState(false);
  const [currPlantObj, setCurrPlantObj] = useState<IPlantMachineDetail | {}>({});
  const [currMachineObj, setCurrMachineObj] = useState<IMachine | {}>({});
  const [indexOfCurrPlant, setIndexOfCurrPlant] = useState<number | null>(null);
  const [indexOfCurrMachine, setIndexOfCurrMachine] = useState<number | null>(null);
  const [isFormAddNewOrEditExisting, setIsFormAddNewOrEditExisting] = useState<"ADD_NEW" | "EDIT_EXISTING" | null>(null);

  const manageIsPlantMachinePresent = (currPlantMachineDetailsArr: IPlantMachineDetail[]) => {
    const IS_PLANT_MACHINE_DETAILS_PRESENT = (() => {
      if (currPlantMachineDetailsArr?.length > 0) {
        return plantMachineDetails?.filter(plant => !plant?.delete)?.length 
          ? true : false;
      }
      return false;
    })();
    setIsPlantMachineDetailsPresent(IS_PLANT_MACHINE_DETAILS_PRESENT);
  };

  /** This function is used to show the pulsing border around the caption input field */
  const handleTogglePulsingCaptionInputFieldForGallery = (flag: boolean) => {
    setShowPulsingCaptionInputFieldBorder(flag);
  };

  useEffect(() => {
    manageIsPlantMachinePresent(plantMachineDetails);
  }, [plantMachineDetails?.length])

  useEffect(() => {
    const userVideoUrl = video?.["videoUrl"];
    if (userVideoUrl?.length) {
      if (
        NON_YOUTUBE_VIDEO_URL.test(userVideoUrl) ||
        YOUTUBE_VIDEO_URL.test(userVideoUrl)
      ) {
        setIsVideoUrlVerified(true);
      } else {
        setIsVideoUrlVerified(false);
      }
    } else {
      setIsVideoUrlVerified(true);
    }
  }, [video]);

  useEffect(() => {
    if (countryCardData?.length) {
      const filteredValue = countryList?.filter(
        (item, index) => !countryCardData.includes(item?.name)
      );
      setCountryList(filteredValue);
    }
  }, [countryCardData]);

  const getCountry = (element: string) => {
    if (!countryLoading) {
      setSelectedCountry(element);
      dispatch(
        searchCountry({
          key: element,
        })
      ).then(
        (res) => {
          const filteredValue = res?.data?.filter(
            (item, index) => !countryCardData.includes(item?.name)
          );
          setCountryList(filteredValue);
        },
        (error) => {}
      );
    }
  };
  const renderCountry = (element: string) => {
    setCountryList([]);

    if (!countryCardData.includes(element))
      setCountryCardData([...countryCardData, element]);
  };
  const getState = (element: string) => {
    if (!stateLoading) {
      dispatch(
        searchState({
          countryCode: "IN",
          state: element,
        })
      ).then(
        (res) => {
          setStateList(res?.data);
        },
        (error) => {}
      );
    }
  };

  const renderState = (element: string) => {
    setSelectedState(element);
    if (!stateCardData.includes(element))
      setStateCardData([...stateCardData, element]);
  };
  const setUserData = () => {
    if (Object.keys(capabilitiesData)?.length) {
      setServiceDesc(capabilitiesData?.serviceOffering);
      setSelectedWork(capabilitiesData?.natureOfWork);
      setIndustriesServed(capabilitiesData?.industriesServed);
      setStateCardData(capabilitiesData?.industriesServedStates);
      setCountryCardData(capabilitiesData?.industriesServedCountries);
      setCustomerListFile(
        capabilitiesData?.customersListFile?.name
          ? [capabilitiesData?.customersListFile]
          : []
      );
      setKeyCustomerDesc(capabilitiesData?.keyCustomers);
      setGalleryData(capabilitiesData?.gallery || []);
      setProductDesc(
        capabilitiesData?.manufacturingCapabilties?.productsManufacturedInhouse
      );
      setPlantDetails(capabilitiesData?.manufacturingCapabilties?.plantDetails);
      setKeyEquipments(
        capabilitiesData?.manufacturingCapabilties?.keyEquipmentsInPlant
        );

      if (capabilitiesData?.manufacturingCapabilties?.plantMachineDetails) {
        const validPlantMachineDetails = capabilitiesData?.manufacturingCapabilties?.plantMachineDetails
          ?.filter((plant, idx) => plant?.delete ? false : true)
          ?.map((plant, idx) => {
            const machines = plant?.machines?.filter((machine, idx) => machine?.delete ? false : true);
            return { ...plant, machines };
          });

        setPlantMachineDetails(validPlantMachineDetails);
      } else {
        setPlantMachineDetails([]);
      }
      setVideo(capabilitiesData?.companyVideo || []);
      setVendorOemTags(getObjectsWithoutNameKey(capabilitiesData?.oemTags));
      setVendorTechCerts(getObjectsWithoutNameKey(capabilitiesData?.technical));
    }
  };

  useEffect(() => {
    if (!neo4jOemTags?.length) dispatch(getNeo4JOEMTags());
  }, []);

  useEffect(() => {
    if (
      isOnboarding &&
      profileCompletionData.hasOwnProperty("profileCompleteness")
    ) {
      if (profileCompletionData?.profileCompleteness) {
        history.push("/userinfo");
      } else {
        if (profileCompletionData?.companyinfoCompleteness) {
          if (profileCompletionData?.capabilitiesCompleteness) {
            dispatch(capabilitiesOnboardingGet({}));
          }
        } else {
          history.push("/onboarding/companyInfo");
        }
      }
    } else {
      if (!isOnboarding) {
        dispatch(capabilitiesOnboardingGet({}));
      }
    }
  }, [profileCompletionData]);

  useEffect(() => {
    if (productDesc?.length || plantDetails?.length || keyEquipments?.length) {
      setIsAddManufactureEdited(true);
    } else {
      setIsAddManufactureEdited(false);
    }
  }, [productDesc, plantDetails, keyEquipments]);
  useEffect(() => {
    if (selectedWork?.length === 0) {
      setOtherWorkState(false);
      setNatureOfWork({
        selectedData: [],
        onChangeData: "",
      });
    }
    const checkOthers = selectedWork?.filter((item) => {
      return item.value === "others";
    });
    if (checkOthers?.length) {
      setOtherWorkState(true);
    } else {
      setNatureOfWork({
        selectedData: [],
        onChangeData: "",
      });
      setOtherWorkState(false);
    }
  }, [selectedWork]);

  useEffect(() => {
    if (selectedDiscipline?.length === 0) {
      setOtherDisciplineState(false);
      setDiscipline({
        selectedData: [],
        onChangeData: "",
      });
    }

    const checkOthers = selectedDiscipline?.filter((item) => {
      return item.value === "others";
    });

    if (checkOthers?.length) {
      setOtherDisciplineState(true);
    } else {
      setDiscipline({
        selectedData: [],
        onChangeData: "",
      });
      setOtherDisciplineState(false);
    }
  }, [selectedDiscipline]);

  useEffect(() => {
    if (
      capabilitiesData &&
      Object.keys(capabilitiesData)?.length !== 0 &&
      loaded
    ) {
      setUserData();
    }
  }, [capabilitiesData]);

  const warning = () => {
    Modal.warning({
      title: "Warning",
      content: "Please fill all the required field in order to save your data ",
      centered: true,
    });
  };

  const validateInput = () => {
    if (
      selectedWork?.length &&
      (industriesServed?.length || checkForMobileScreen()) &&
      stateCardData?.length &&
      (keyCustomerDesc?.length || customerListFile?.length) &&
      !IS_SERVICE_DESC_ERROR
    ) {
      validateData = true;
    } else {
      validateData = false;
    }
  };

  const handleClick = (type, clickedBy = "desktopUser") => {
    if (type === "edit") {
      setSaveState(false);
    }
    validateInput();
    if (type === "save") {
      const gstn = getGstn();
      setIsDataSaving(true);
      setSaveState(true);
      let oemTags = vendorOemTags.filter((tag) => tag?.name); //to remove empty enteries
      oemTags = oemTags.reduce(
        (acc, tag) =>
          !acc.find((itr) => itr?.name === tag?.name) ? [...acc, tag] : acc, //to remove duplicate enteries in case user added
        []
      );
      // const finalServiceDesc = serviceDesc?.replace(/(<div><br><\/div>)+$/g, '') || "";
      setVendorOemTags(oemTags);
      setVendorTechCerts((certs) => certs.filter((cert) => cert?.name));
      const oem = {
        gstn,
        document: oemTags,
      };
      const technical = vendorTechCerts.filter((cert) => cert?.name);
      //remove percent & isNotPresentInDB keys from video object
      video = video?.map((item) => {
        delete item.percent;
        delete item.isNotPresentInDB;
        return item;
      });
      //filter if item.name is not Connection Error! or Upload Cancelled!
      video = video.filter(
        (item) =>
          item.name !== "Connection Error!" && item.name !== "Upload Cancelled!"
      );
      const galleryPayloadData = (() => {
        let finalGalleryData = [];
        if (galleryData?.images?.length) {
          finalGalleryData = galleryData?.images?.map((item) => {
            delete item.isNotPresentInDB;
            return item;
          });
        } else if (galleryData?.length) {
          finalGalleryData = galleryData?.map((item) => {
            delete item.isNotPresentInDB;
            return item;
          });
        }
        return finalGalleryData;
      })();

      const body = {
        gstn: gstn,
        industriesServedStates: stateCardData,
        industriesServedCountries: countryCardData,
        keyCustomers: keyCustomerDesc,
        industriesServed: industriesServed,
        natureOfWork: selectedWork,
        // serviceOffering: finalServiceDesc,
        serviceOffering: serviceDesc,
        customersListFile: customerListFile[0],
        gallery: galleryPayloadData,
        manufacturingCapabilties: {
          productsManufacturedInhouse: productDesc,
          plantDetails: plantDetails,
          keyEquipmentsInPlant: keyEquipments,
          plantMachineDetails: plantMachineDetails || [],
        },
        companyVideo: video,
        oem,
        technical,
      };
      const updateBody = {
        gstn: gstn,
        customersListFile: customerListFile[0],
        gallery: galleryData?.images || galleryData,
        capabilitiesId: capabilitiesData?.capabilitiesId?.length
          ? capabilitiesData?.capabilitiesId
          : capabilityId,
        industriesServedCountries: countryCardData,
        keyCustomers: keyCustomerDesc.length
          ? [...keyCustomerDesc?.split(",")]
          : [],
        serviceCapabilities: {
          // description: finalServiceDesc,
          description: serviceDesc,
          natureOfWork: {
            name: selectedWork,
          },
        },

        industryServed: { name: industriesServed },
        locationServed: [{ state: stateCardData }],
        manufacturingCapabilities: {
          keyEquipments: keyEquipments.map((item) => {
            return {
              capacity: "",
              equipment: item,
              make: "",
            };
          }),
          plantLocation: plantDetails.map((item) => {
            return {
              city: item,
              plantCapacity: "",
            };
          }),
          products: [productDesc],
          plantMachineDetails: plantMachineDetails || [],
        },
        companyVideo: video,
        oem,
        technical,
      };

      //onsucess of saving api
      const onHandleSuccess = () => {
        resetProfileCompletionStatus(dispatch);
        updateProfileFlagToTrue("isCapabilitiesCompleted");
        toggleIsPageEdited(dispatch, false);
        history.push("/onboarding/experience");
      };

      //being saved
      if (validateData && clickedBy === "mobileUser") {
        //invoking additional information modal popup for responsive screens
        clickedBy === "mobileUser" && setShowCertificates(true);
        profileLayoutError(dispatch, false);
        dispatch(capabilitiesOnboardingPost(body));
      }
      if (validateData && clickedBy === "desktopUser") {
        profileLayoutError(dispatch, false);

        //checking showCertificates value to see if the user is on mobile screen and has opened the certifcates popup in which case we should be updating the data
        if (isOnboarding && !showCertificates) {
          dispatch(capabilitiesOnboardingPost(body)).then((res) => {
            setCapabilityId(res.capabilitiesId);
            onHandleSuccess();
          });
        } else {
          dispatch(capabilitiesUpdate(updateBody))
            .then((res) => {
              if (!checkForMobileScreen()) {
                notificationFunc("success");
              }
              setVideo(prev => prev.filter(item => item?.name !== "Connection Error!" && item?.name !== "Upload Cancelled!" ));
              setIsDataSaving(false);
              toggleIsPageEdited(dispatch, false);
              showCertificates && onHandleSuccess();
            })
            .catch(() => {
              setIsDataSaving(false);
            });
        }
      } else {
        setIsDataSaving(false);
        profileLayoutError(dispatch, true);
      }
    }
  };

  const handleNatureOfWorkChange = (e: any) => {
    setNatureOfWork({
      ...natureOfWork,
      onChangeData: e.target.value,
    });
  };

  const addNatureOfWorkTags = (e: any) => {
    natureOfWork.onChangeData?.length !== 0 &&
      !natureOfWork.selectedData.includes(natureOfWork.onChangeData) &&
      setNatureOfWork({
        ...natureOfWork,
        selectedData: [...natureOfWork.selectedData, natureOfWork.onChangeData],
      });
  };

  const addDisciplineTags = (e: any) => {
    discipline.onChangeData?.length !== 0 &&
      !discipline.selectedData.includes(discipline.onChangeData) &&
      setDiscipline({
        ...discipline,
        selectedData: [...discipline.selectedData, discipline.onChangeData],
      });
  };

  const addProductsTags = (e: any) => {
    products.onChangeData?.length !== 0 &&
      !products.selectedData.includes(products.onChangeData) &&
      setProducts({
        ...products,
        selectedData: [...products.selectedData, products.onChangeData],
      });
  };

  const handleCityChange = (e: any) => {
    setCity(e.target.value);
  };

  const handleCapacityChange = (e: any) => {
    setCapacityPlantLocation(e.target.value);
  };

  const addPlantation = () => {
    if (city?.length > 0 && capacityPlantLocation?.length > 0) {
      setPlantLocation([
        ...plantLocation,
        { city: city, plantCapacity: capacityPlantLocation },
      ]);
      setCity("");
      setCapacityPlantLocation("");
    }
  };

  const handlePlantLocationDelete = (e, value) => {
    setPlantLocation([
      ...plantLocation?.filter((item, index) => {
        return index !== value;
      }),
    ]);
  };

  const handleEquipmentNameChange = (e: any) => {
    setKeyEquipmentName(e.target.value);
  };
  const handleEquipmentMakeChange = (e: any) => {
    setkeyEquipmentMake(e.target.value);
  };
  const handleEquipmentCapacityChange = (e: any) => {
    setkeyEquipmentCapacity(e.target.value);
  };
  const handleTextChange = (e: any, type) => {
    if (type === "service") {
      setServiceDesc(e);
    } else if (type === "customer") {
      setKeyCustomerDesc(e.target.value.trimStart());
    } else {
      setProductDesc(e.target.value.trimStart());
    }
  };

  const addkeyEquipment = () => {
    if (
      keyEquipmentName?.length > 0 &&
      keyEquipmentMake?.length > 0 &&
      keyEquipmentCapacity?.length > 0
    ) {
      setKeyEquipment([
        ...keyEquipment,
        {
          equipment: keyEquipmentName,
          make: keyEquipmentMake,
          capacity: keyEquipmentCapacity,
        },
      ]);
      setKeyEquipmentName("");
      setkeyEquipmentMake("");
      setkeyEquipmentCapacity("");
    }
  };

  const handleKeyEquipmentDelete = (e, value) => {
    setKeyEquipment([
      ...keyEquipment?.filter((item, index) => {
        return index !== value;
      }),
    ]);
  };

  const closeCard = (caseType, data: any) => {
    switch (caseType) {
      case "Close_State":
        if (data === selectedState) {
          setSelectedState("Select States/ UTs");
        }
        setStateCardData([
          ...stateCardData?.filter((item) => {
            return data !== item;
          }),
        ]);
        break;
      case "Close_Country":
        if (data === selectedCountry) {
          setSelectedCountry("Select Countries");
        }
        setCountryCardData([
          ...countryCardData?.filter((item) => {
            return data !== item;
          }),
        ]);
        break;
      case "Close_Certificate":
        setCustomerListFile([
          ...customerListFile?.filter((item) => {
            return data.name !== item.name;
          }),
        ]);
        break;
      case "Close_Nature_Of_Work":
        setSelectedWork([
          ...selectedWork?.filter((item) => {
            return data.value !== item.value;
          }),
        ]);
        break;
      case "Close_Industries_Served":
        setIndustriesServed([
          ...industriesServed?.filter((item) => {
            return data.value !== item.value;
          }),
        ]);
        break;

      case "Close_Plant_Details":
        setPlantDetails([
          ...plantDetails?.filter((item) => {
            return data !== item;
          }),
        ]);
        break;
      case "Close_Key_Equipments":
        setKeyEquipments([
          ...keyEquipments?.filter((item) => {
            return data !== item;
          }),
        ]);
        break;
      case "Close_Discipline":
        setSelectedDiscipline([
          ...selectedDiscipline?.filter((item) => {
            return data.value !== item.value;
          }),
        ]);
        break;
      case "Close_Discipline_Add":
        setDiscipline({
          ...discipline,
          selectedData: discipline.selectedData?.filter(
            (item) => item !== data
          ),
        });
        break;
      case "Close_Products_Add":
        setProducts({
          ...products,
          selectedData: products.selectedData?.filter((item) => item !== data),
        });
        break;
      default:
    }
    toggleIsPageEdited(dispatch, true);
  };

  const getFile = (e: any, type: "CUSTOMER" | "OEM" | "CERT", index = -1) => {
    let file = e.target.files[0];
    let dupFileFlag = false;
    if (type === "CUSTOMER")
      dupFileFlag = customerListFile?.some(
        (item: any) => item?.name === file?.name
      );

    if (typeof file !== "undefined" && file?.size < 20971520 && !dupFileFlag) {
      //object to array

      dispatch(fileUploadPost(file)).then(
        (res) => {
          if (type === "CUSTOMER")
            setCustomerListFile([{ name: file?.name, file: res }]);
          if (type === "OEM") {
            setVendorOemTags((tags) => {
              tags[index]["documentFile"] = res;
              tags[index]["verificationStatus"] = VerificationStatus.verified;
              return tags;
            });
          }
          if (type === "CERT") {
            setVendorTechCerts((tags) => {
              tags[index]["certificationFile"] = res;
              tags[index]["verificationStatus"] = VerificationStatus.verified;
              return tags;
            });
          }
          setTempDoc([]);
          toggleIsPageEdited(dispatch, true);
        },
        (error) => {
          setTempDoc([]);
        }
      );
      setTempDoc([{ name: file?.name, file: { fileSize: file?.size } }]);
    } else {
      if (file?.size > 20971520) {
      }
    }
  };

  const onHandleScroll = (id) => {
    if (!isFirstError) {
      handleScrollToView(id);
      isFirstError = true;
    }
    return true;
  };

  const updateOemCert = (
    type: "OEM" | "CERT",
    method: "UPDATE" | "DELETE" | "CREATE",
    value = "",
    index = -1
  ) => {
    if (method === "CREATE") {
      if (type === "OEM") {
        const lastItem = vendorOemTags[vendorOemTags.length - 1];
        if (!lastItem || lastItem?.name)
          setVendorOemTags((tags) => [
            ...tags,
            { name: value, verificationStatus: VerificationStatus.inProgress },
          ]);
      } else {
        const lastItem = vendorTechCerts[vendorTechCerts.length - 1];
        if (!lastItem || lastItem?.name)
          setVendorTechCerts((cert) => [
            ...cert,
            { name: value, verificationStatus: VerificationStatus.inProgress },
          ]);
      }
    }

    if (index > -1) {
      if (method === "UPDATE") {
        if (type === "OEM") {
          const tagDetails = oemOptionsLibrary.find(
            (tag) => tag.name === value
          );
          setVendorOemTags((tags) =>
            tags.map((tag, idx) => {
              if (idx === index) {
                return {
                  ...tag,
                  name: tagDetails?.name || value,
                  id: tagDetails?._id,
                  verificationStatus: VerificationStatus.inProgress,
                };
              }
              return tag;
            })
          );
        } else {
          setVendorTechCerts((certs) =>
            certs.map((cert, idx) => {
              if (idx === index) {
                return {
                  ...cert,
                  name: value,
                  verificationStatus: VerificationStatus.inProgress,
                };
              }
              return cert;
            })
          );
        }
      } else {
        type === "OEM" &&
          setVendorOemTags((tags) => tags.filter((tag, idx) => idx !== index));
        type === "CERT" &&
          setVendorTechCerts((certs) =>
            certs.filter((cert, idx) => idx !== index)
          );
      }
    }
    toggleIsPageEdited(dispatch, true);
  };

  useEffect(() => {
    dispatch(getTechCerts())
      .then((res) => {
        setTechCertsOptionsLibrary(res?.certs.map((cert) => cert.name));
        setTechCertsOptions(res?.certs.map((cert) => cert.name));
      })
      .catch(() => {
        setTechCertsOptions([]);
      });
    dispatch(getOemTags())
      .then((res) => {
        setOemOptionsLibrary(res?.data?.tags);
        setOemOptions(res?.data?.tags.map((tag) => tag.name));
      })
      .catch(() => {
        setOemOptions([]);
      });
  }, []);

  useEffect(() => {
    const selectedTechCerts = vendorTechCerts.map((cert) => cert.name);
    setTechCertsOptions(
      techCertsOptionsLibrary.filter(
        (option) => !selectedTechCerts.includes(option)
      )
    );
  }, [vendorTechCerts]);

  useEffect(() => {
    const selectedOemTags = vendorOemTags.map((tag) => tag.name);
    const oemOptionsNameLibrary = oemOptionsLibrary.map(
      (option) => option.name
    );
    setOemOptions(
      oemOptionsNameLibrary.filter(
        (option) => !selectedOemTags.includes(option)
      )
    );
  }, [vendorOemTags]);

  const renderKeyCustomerCertificate = () => {
    return (
      <>
        {tempDoc?.map((item) => {
          return (
            <CardWrapper>
              <InputDivWrap>
                <CardTag
                  cardText={item.name || "temp"}
                  usedFor="fileUploadTag"
                  closable={true}
                  loading
                  height="40px"
                  labelFontVariant="medium"
                  labelFontColor="#215EC9"
                />
              </InputDivWrap>
            </CardWrapper>
          );
        })}
        {customerListFile?.length &&
          customerListFile?.map((item) => {
            return (
              <CardWrapper>
                <InputDivWrap>
                  <CardTag
                    data={tempDoc?.length ? tempDoc[0] : item}
                    onClose={() => closeCard("Close_Certificate", item)}
                    cardText={item?.name || "cer"}
                    usedFor="selectItem"
                    fileSize={item?.file?.fileSize / 1000000}
                    closable={false}
                    cursorType={"pointer"}
                    height="40px"
                    labelFontVariant="medium"
                    labelFontColor="#215EC9"
                  />
                </InputDivWrap>
              </CardWrapper>
            );
          })}
      </>
    );
  };
  const renderCertificates = () => {
    return (
      <>
        <FlexColumn $respScreenStyle>
          <FormField
            isStar={false}
            label="Technical Certifications"
            info=""
            showError={false}
            isPaddingNotRequired={true}
          >
            <TextDiv>
              Upload active licenses/certificates, eg- IBR III-C, ASME UV
            </TextDiv>
          </FormField>
          <ColumnDiv $respScreenRowGap={"0px"}>
            {vendorTechCerts.length === 0 && (
              <CertificateBttns
                $borderRadius={"8px"}
                $height={"40px"}
                $width={"550px"}
                $usedForResponsiveScreen={true}
                onClick={() => updateOemCert("CERT", "CREATE")}
                data-tracking-id={
                  HEAP_DATA_ATTRIBUTES.CAPABILITIES.EXPAND.CERTIFICATE
                }
              >
                + Add Technical Certificates
              </CertificateBttns>
            )}
            {vendorTechCerts.length > 0 && (
              <>
                {vendorTechCerts.map((tag, index) => (
                  <CertOrOem
                    optionsLibrary={techCertsOptionsLibrary}
                    selectedValue={tag.name}
                    uploadedDoc={tag?.certificationFile}
                    getFile={getFile}
                    handleChange={updateOemCert}
                    key={tag._id || index}
                    index={index}
                    usedFor="CERT"
                    inputPlaceholder="Enter Certificate Name"
                    uploadText="Upload"
                    verificationStatus={tag?.verificationStatus}
                    dropDownTrackingId={
                      HEAP_DATA_ATTRIBUTES.CAPABILITIES.NAME.CERTIFICATE
                    }
                  />
                ))}
                <AddBttn
                  onClick={() => updateOemCert("CERT", "CREATE")}
                  $top={"20px"}
                  $right={"20px"}
                >
                  + Add New
                </AddBttn>
              </>
            )}
          </ColumnDiv>
        </FlexColumn>
        <FlexColumn $respScreenStyle>
          <ManufactureTextAreaWrapper>
            <ProfileInputHeading
              isStar={false}
              isPaddingNotRequired
              name="Products manufactured inhouse"
            />
            <TextAreaDiv
              placeholder={`Enter in comma-separated format. Eg: Forced draft cooling tower, Cross Flow Cooling Tower`}
              onChange={(e) => {
                handleTextChange(e, "product");
                toggleIsPageEdited(dispatch, true);
              }}
              value={productDesc}
              data-trackingid={
                HEAP_DATA_ATTRIBUTES.CAPABILITIES.PRODUCT_INHOUSE
              }
            ></TextAreaDiv>
          </ManufactureTextAreaWrapper>
          {/* <FormField
            isStar={false}
            label="In-house Manufacturing Capabilities"
            info=""
            showError={false}
            isPaddingNotRequired={true}
          >
            <TextDiv style={{ marginBottom: "12px" }}>
              Applicable for in-house manufacturing capabilities - Include
              details of your product ranges and manufacturing facilities.
            </TextDiv>
          </FormField> 
          <ManufacturingCapabilities
            isAddManufacturingTrue={isAddManufactureEdited}
          >
            // Plant Details
            <DropdownWithTagDiv>
              <ProfileInputHeading isStar={false} name="Plant Details" />
              <TwoInputField
                buttonOnclick={(value) => {
                  if (!plantDetails.includes(value))
                    setPlantDetails([...plantDetails, value]);
                  toggleIsPageEdited(dispatch, true);
                }}
                placeholder="Enter plant-wise information- city/ town, capacity (eg: 500 MT/month), products, factory certifications etc"
                usedFor="PLANT_DETAILS"
              ></TwoInputField>
              {plantDetails?.map((item) => {
                return (
                  <ManufacturingCardWrapper>
                    <CardTag
                      onClose={() => closeCard("Close_Plant_Details", item)}
                      usedFor="selectItem"
                      cardText={item}
                      closable={false}
                      multiLine={true}
                      labelFontColor="#215EC9"
                    />
                  </ManufacturingCardWrapper>
                );
              })}
            </DropdownWithTagDiv>

            // Key Equipments in Plant
            <DropdownWithTagDiv>
              <ProfileInputHeading
                isStar={false}
                name="Key Equipments in Plant"
              />
              <TwoInputField
                buttonOnclick={(value) => {
                  if (!keyEquipments.includes(value))
                    setKeyEquipments([...keyEquipments, value]);
                  toggleIsPageEdited(dispatch, true);
                }}
                placeholder="Enter List of key equipments used at manufacturing facility (eg: Bystronic laser cutting CNC machine)"
              ></TwoInputField>
              {keyEquipments?.map((item) => {
                return (
                  <ManufacturingCardWrapper>
                    <CardTag
                      onClose={() => closeCard("Close_Key_Equipments", item)}
                      usedFor="selectItem"
                      cardText={item}
                      closable={false}
                      multiLine={true}
                      labelFontColor="#215EC9"
                    />
                  </ManufacturingCardWrapper>
                );
              })}
            </DropdownWithTagDiv>
          </ManufacturingCapabilities> */}
        </FlexColumn>
        <FlexColumn $respScreenStyle>
          <FormField
            isStar={false}
            label="Other OEM Partnership"
            info=""
            showError={false}
            isPaddingNotRequired={true}
          >
            <TextDiv>
              Applicable for OEM partners/ authorised service providers -
              Include details along with authorisation letter/ certificate.
            </TextDiv>
          </FormField>
          <ColumnDiv $respScreenRowGap={"0px"}>
            {vendorOemTags.length === 0 && (
              <AddManuallyBttn
                $background={"#FFFFFF"}
                onClick={() => updateOemCert("OEM", "CREATE")}
                $borderRadius={"8px"}
                $height={"40px"}
                $width={"550px"}
                $usedForResponsiveScreen={true}
                data-tracking-id={HEAP_DATA_ATTRIBUTES.CAPABILITIES.EXPAND.OEM}
              >
                + Add OEM Partnership Info
              </AddManuallyBttn>
            )}
            {vendorOemTags.length > 0 && (
              <>
                {vendorOemTags.map((tag, index) => (
                  <CertOrOem
                    optionsLibrary={oemOptions}
                    selectedValue={
                      tag.name.length > 15
                        ? `${tag.name.slice(0, 15)}...`
                        : tag.name
                    }
                    uploadedDoc={tag?.documentFile}
                    getFile={getFile}
                    handleChange={updateOemCert}
                    key={tag._id || index}
                    index={index}
                    usedFor="OEM"
                    inputPlaceholder="Enter OEM Name"
                    uploadText="Upload"
                    verificationStatus={tag?.verificationStatus}
                    dropDownTrackingId={
                      HEAP_DATA_ATTRIBUTES.CAPABILITIES.NAME.OEM
                    }
                  />
                ))}
                <AddBttn
                  onClick={() => updateOemCert("OEM", "CREATE")}
                  $top={"20px"}
                  $right={"20px"}
                >
                  + Add New
                </AddBttn>
              </>
            )}
          </ColumnDiv>
        </FlexColumn>
      </>
    );
  };

  const renderKeyCustomerUI = () => {
    return (
      <>
        <TextAreaDiv
          data-tracking-id={HEAP_DATA_ATTRIBUTES.CAPABILITIES.KEY_CUSTOMERS}
          placeholder={`Enter in comma-separated format.\nEg: ITC, Pfizer, HUL`}
          onChange={(e) => {
            toggleIsPageEdited(dispatch, true);
            handleTextChange(e, "customer");
          }}
          value={keyCustomerDesc}
          error={
            saveState && !customerListFile?.length && !keyCustomerDesc?.length
              ? onHandleScroll("keyCustomer")
              : false
          }
        ></TextAreaDiv>
      </>
    );
  };

  const renderKeyCustomerMobileUI = () => {
    return (
      <>
        <p>Add as text or upload list of your key customers</p>
        <BttnContainer>
          <InputDivWrap style={{ maxWidth: "44.15vw", height: "40px" }}>
            {tempDoc?.length || customerListFile?.length ? (
              <KeyCustomerFileRespView>
                {renderKeyCustomerCertificate()}
              </KeyCustomerFileRespView>
            ) : (
              <Bttn
                onClick={() => {}}
                $error={
                  saveState &&
                  !customerListFile?.length &&
                  !keyCustomerDesc?.length
                }
              >
                <UploadIcon
                  src={
                    saveState &&
                    !customerListFile?.length &&
                    !keyCustomerDesc?.length
                      ? "/images/upload.svg"
                      : "/images/upload_white.svg"
                  }
                  alt="upload"
                />
                Upload
                <InputWrap
                  type="file"
                  onChange={(e) => getFile(e, "CUSTOMER")}
                  accept="application/pdf,image/jpeg,.xml,.xlsx,.xls,.docx"
                  disabled={false}
                  style={{ width: "280px", height: "40px" }}
                />
              </Bttn>
            )}
          </InputDivWrap>
          <p style={{ marginTop: "12px" }}>OR</p>
          <AddManuallyBttn
            $width={"34.155vw"}
            $isOpen={addManuallyBttnState}
            $borderRadius={addManuallyBttnState ? "8px 8px 0px 0px" : "8px"}
            $height={addManuallyBttnState ? "60px" : "40px"}
            onClick={() => setAddManuallyBttnState(!addManuallyBttnState)}
          >
            {addManuallyBttnState ? "Add Manually -" : "Add Manually +"}
          </AddManuallyBttn>
        </BttnContainer>
        {addManuallyBttnState && (
          <KeyCustomerDescContainer
            $borderRadius={addManuallyBttnState ? "8px 0px 8px 8px" : "8px"}
          >
            <LineHider></LineHider>
            {renderKeyCustomerUI()}
          </KeyCustomerDescContainer>
        )}
      </>
    );
  };
  const mobileScreenCheck = () => {
    if (window.innerWidth < 640) {
      return true;
    }
    return false;
  };
  const handleStatesOnClick = () => {
    if (mobileScreenCheck()) {
      setModalOpenState(true);
      setModalData({
        data: stateCardData,
        type: "states",
        headerName: "Select Locations Served",
      });
    }
  };
  const handleScopeOfWOrkOnClick = () => {
    if (mobileScreenCheck()) {
      setModalOpenState(true);
      setModalData({
        data: selectedWork,
        type: "scopeOfWork",
        headerName: "Select Scope of Work",
      });
    }
  };

  useEffect(() => {
    if (modalData.type === "states") {
      setModalData({
        data: stateCardData,
        type: "states",
        headerName: "Select Locations Served",
      });
    } else {
      setModalData({
        data: selectedWork,
        type: "scopeOfWork",
        headerName: "Select Scope of Work",
      });
    }
  }, [selectedWork, stateCardData]);

  useEffect(() => {
    if (stateCardData?.length) {
      setStateDropdownDetails([
        ...stateGeoDbData.filter((item) => stateCardData.includes(item)),
        ...stateGeoDbData.filter((item) => !stateCardData.includes(item)),
      ]);
    } else {
      setStateDropdownDetails(stateGeoDbData);
    }
  }, [stateCardData]);

  useEffect(() => {
    profileLayoutError(dispatch, false);
    toggleIsPageEdited(dispatch, false);
  }, []);

  /** scroll to service offering section */
  useEffect(() => {
    const scrollToServiceOffering = LocalStorageService.get(
      AppConfig.STORAGE_KEYS.scrollToServiceOffering
    );
    if (scrollToServiceOffering) scrollToElementId("serviceOffering");

    return () => {
      /** after scrolling, 
       * remove scroll to service offering from local storage */
      LocalStorageService.remove(AppConfig.STORAGE_KEYS.scrollToServiceOffering);
    };
  }, []);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsImageUploading(true);
    const tempGalleryData = galleryData;
    const uploadedFiles = e?.target?.files;
    let filesWithErrors: Array<File> = [];
    let filesWithoutErrors: Array<File> = [];

    const TWENTY_MB_IN_BYTES = getBytesFromMB(20);

    const SUPPORTED_FILE_TYPES = [
      COMMON_FILE_TYPES.PNG,
      COMMON_FILE_TYPES.JPG,
      COMMON_FILE_TYPES.JPEG,
      COMMON_FILE_TYPES.WEBP,
    ];

    if (uploadedFiles?.length) {
      for (let i = 0; i < uploadedFiles?.length; i++) {
        /** prepare success and error files **/
        const uploadedFile = uploadedFiles[i];
        const isFileAbsent = !uploadedFile;
        const isFileNotSupported = !SUPPORTED_FILE_TYPES?.includes(
          (uploadedFile?.name as any)?.split(".")?.pop()?.toLowerCase()
        );
        const isDuplicateFile = tempGalleryData?.some(
          existingFile => existingFile?.imageName === uploadedFile?.name
        );
        const doesFileExceedIndividualFileSizeLimit = uploadedFile?.size > TWENTY_MB_IN_BYTES;

        if (isFileAbsent
          || isFileNotSupported
          || isDuplicateFile
          || doesFileExceedIndividualFileSizeLimit
        ) {
          filesWithErrors.push(uploadedFile);
        } else {
          filesWithoutErrors.push(uploadedFile);
        }
      }

      if (filesWithErrors?.length) {
        setIsImageUploading(false);
        AntdToast({
          duration: null,
          type: "error",
          message: `Please upload unique files (PNG, JPG, JPEG & WEBP formats) with each file size not exceeding 20MB.`,
          description: `Upload failed for: ${filesWithErrors?.map((fileObj, index) => `${index+1}. ${fileObj?.name || ""}`)?.join(";  ")}`,
        });
      }

      if (filesWithoutErrors?.length) {
        const formData = new FormData();
        for (let i = 0; i < filesWithoutErrors?.length; i++) {
          formData.append(FORM_DATA_KEY, filesWithoutErrors[i]);
        }

        /** Handle File Upload with single API call */
        dispatch(FileOperationsAction.uploadFilesToPermanentFolder(formData))
          .then((permanentFiles) => {
            heapTrackTrigger({
              windowObj: window,
              eventName:
                HEAP_DATA_ATTRIBUTES.CAPABILITIES.UPLOAD.BULK_IMAGE_UPLOAD_SUCCESS,
              value: {
                successfulGalleryImageUploads: permanentFiles
                  ?.map((fileObj) => `1. ${fileObj?.name || fileObj?.file?.fileName} `)
                  ?.join("; "),
              },
            });
            permanentFiles?.forEach((fileObj) => {
              tempGalleryData.push({
                file: fileObj,
                category: selectedImageCategory || OTHER_CATEGORY,
                description: "image",
                imageName: fileObj?.name || fileObj?.fileName?.fileUrl,
                isNotPresentInDB: true,
              });
            });
            setSelectedImageCategory("");
            setGalleryData(tempGalleryData);
            setIsImageUploading(false);
            toggleIsPageEdited(dispatch, true);
          }).catch((err) => {
            setIsImageUploading(false);
            setSelectedImageCategory("");
            AntdToast({
              type: "error",
              message: `Error`,
              description: `Unable to upload Images`,
            });
          });

      }
    }

    /** reset input value for same file upload */
    e.target.value = "";
  }

  const handleVideoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSaveChangesLoading(true);
    toggleIsPageEdited(dispatch, false);
    //convert to array
    const tempVideoData = video;
    const uploadedFiles = e?.target?.files || [];
    const duplicateFiles: Array<File> = [];
    let tempFile = [];

    if (uploadedFiles?.length) {
      /** Check and Remove duplicate files */
      for (let i = 0; i < uploadedFiles?.length; i++) {
        const uploadedFile: File = uploadedFiles[i];
        const isDuplicate = tempVideoData?.some(
          existingFile => existingFile?.name === uploadedFile?.name
        );

        if (isDuplicate) {
          duplicateFiles.push(uploadedFile);
        } else {
          tempFile.push(uploadedFile);
        }
      };
    }

    if (duplicateFiles?.length) {
      /** Show error message for duplicate files */
      AntdToast({
        duration: null,
        type: "error",
        message: "Please upload unique files with each file size not exceeding 20MB.",
        description: `Duplicate Files: ${duplicateFiles?.map((fileObj, index) => `${index+1}. ${fileObj?.name || ""}`)?.join(";  ")}`,
      });
    }

    let loopedTimes: number = 0;
    for (let i = 0; i < tempFile?.length; i++) {
      //max size 20mb
      if (tempFile[i].size > 20000000) {
        AntdToast({
          type: "error",
          message: `${tempFile[i].name} is greater than 20mb`,
          description: "",
        });
        continue;
      }
      tempVideoData.push({
        file: null,
        category: selectedVideoCategory || OTHER_CATEGORY,
        videoUrl: null,
        name: tempFile[i].name,
        percent: 0,
        isNotPresentInDB: true,
      });
      setVideo(tempVideoData);

      dispatch(
        fileUploadPostWithPercentage(
          tempFile[i],
          (progressEvent: any) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor(
              (loaded * 100) / total
            );
            //check if the file is already present video array
            const tempVideoData = video;
            const index = tempVideoData.findIndex(
              (video: any) =>
                video.name === tempFile[i].name &&
                video.file === null
            );
            if (index !== -1) {
              setVideo((prev: any) => {
                const temp = [...prev];
                if (temp[index]) {
                  temp[index].percent = percent;
                  temp[index].isNotPresentInDB = true;
                }
                return temp;
              });
            }
          }
        )
      )
        .then(async (response) => {
          const tempVideoData = video;
          const index = tempVideoData.findIndex(
            (video: any) =>
              video.name === tempFile[i].name &&
              video.file === null
          );
          await dispatch(fileUploadPermanent(response.data))
            .then((value) => {
              heapTrackTrigger({
                windowObj: window,
                eventName:
                  HEAP_DATA_ATTRIBUTES.CAPABILITIES.UPLOAD
                    .BULK_VIDEO_UPLOAD,
                value: {
                  videoName: value[0].fileName,
                  videoSize: value[0].fileSize,
                },
              });
              setVideo((prev: any) => {
                const temp = [...prev];
                if (temp[index]) {
                  temp[index].file = value[0];
                  temp[index].percent = 100;
                  temp[index].isNotPresentInDB = true;
                }
                return temp;
              });
              setVideo(tempVideoData);
            })
            .then(() => {
              loopedTimes += 1;
              let filteredTimes = tempVideoData.filter(
                (video: any) => video.percent === 100
              ).length;
              let connectionFailedTimes = tempVideoData.filter(
                (video: any) =>
                  video.name === "Connection Error!"
              ).length;
              //if loppedTimes if equal to tempFile.length of item with percent === 100
              if (filteredTimes === tempFile.length) {
                toggleIsPageEdited(dispatch, true);
                setIsSaveChangesLoading(false);
                setSelectedVideoCategory("");
              }
              if (filteredTimes - (filteredTimes - tempFile.length) === tempFile.length) {
                toggleIsPageEdited(dispatch, true);
                setIsSaveChangesLoading(false);
                setSelectedVideoCategory("");
              }
            });
        })
        .catch((err) => {
          setIsSaveChangesLoading(false);
          setSelectedVideoCategory("");
          const tempVideoData = video;
          const index = tempVideoData.findIndex(
            (video: any) =>
              video.name === tempFile[i].name &&
              video.file === null
          );

          if (index !== -1) {
            setVideo((prev: any) => {
              const temp = [...prev];
              if (temp[index]) {
                temp[index].file = null;
                temp[index].name = "Connection Error!";
                temp[index].isNotPresentInDB = true;
              }
              return temp;
            });
          }
          setVideo(tempVideoData);
        });
    }
  };

  const handleSaveVideoURL = () => {
    const regex = /^(https?):\/\/(?:www\.)?([\w.-]+)\.([a-z]{2,})(?:\/[\w.-]*)*\/?(?:\?.*)?$/;
    let url;
    try {
      url = new URL(typedVideoUrl);
    } catch (e) {
      AntdToast({
        type: "error",
        message: "Please enter a valid url",
        description: "",
      });
      return;
    }

    const UPLOADED_VIDEO_URL = url.href;
    const indexOfNewVideoInExistingCompanyVideos = video?.findIndex(existingVideoObj => {
      return existingVideoObj?.videoUrl === UPLOADED_VIDEO_URL 
        || existingVideoObj?.file?.fileUrl === UPLOADED_VIDEO_URL;
    })
    const IS_EXISTING_VIDEO = indexOfNewVideoInExistingCompanyVideos >= 0;
    if (IS_EXISTING_VIDEO) {
      /** exclude duplicate entries */
      AntdToast({ type: "error", message: "Video Link is already present.", description: "" });
      return;
    };

    if (regex.test(url.href)) {
      setVideo((prev: any) => {
        //check if it is a valid url
        const temp = [...prev];
        temp.push({
          category: selectedVideoCategory || OTHER_CATEGORY,
          videoUrl: typedVideoUrl,
          name: typedVideoUrl,
          isNotPresentInDB: true,
        });
        return temp;
      });
      setTypedVideoUrl("");
      toggleIsPageEdited(dispatch, true);
    } else {
      AntdToast({
        type: "error",
        message: "Please enter a valid url",
        description: "",
      });
    }
  };

  if (showCertificates) {
    return (
      <>
        <AddInfoModal
          wrapClassName="modalStyle"
          mask={false}
          title={"+ Additional Information"}
          maskClosable={false}
          footer={
            <ButtonWrapper
              $padding={"10px 0px 20px 0px"}
              $isAddInfoOpen={showCertificates}
            >
              <ProfileCreationButtons
                isloading={isDataSaving}
                buttonText={isOnboarding ? "SAVE & CONTINUE" : "SAVE CHANGES"}
                usedAt={"onboarding"}
                pagination={true}
                pageNumber={3}
                state={!buttonState.isPageEdited}
                handleStateChange={(value) => {
                  if (value === "cancel") {
                    setUserData();
                  } else {
                    isFirstError = true;
                    handleClick(value);
                  }
                }}
              ></ProfileCreationButtons>
            </ButtonWrapper>
          }
          visible={true}
          zIndex={0}
          closeIcon={<img src="/images/close-blue.svg" alt="close button" />}
          maskStyle={{
            width: "calc(100vh - 123px)",
            background: "none",
            backdropFilter: "none",
            height: "calc(100vh - 60px)",
          }}
          onCancel={() => {
            setShowCertificates(false);
          }}
        >
          <DisplayCertOnRespScreen>
            {renderCertificates()}
          </DisplayCertOnRespScreen>
        </AddInfoModal>
        <ButtonWrapper
          $padding={"10px 0px 20px 0px"}
          $isAddInfoOpen={showCertificates}
        >
          <ProfileCreationButtons
            isloading={isDataSaving}
            buttonText={isOnboarding ? "SAVE & CONTINUE" : "SAVE CHANGES"}
            usedAt={"onboarding"}
            pagination={true}
            pageNumber={3}
            state={!buttonState.isPageEdited}
            handleStateChange={(value) => {
              if (value === "cancel") {
                setUserData();
              } else {
                isFirstError = true;
                handleClick(value);
              }
            }}
          ></ProfileCreationButtons>
        </ButtonWrapper>
      </>
    );
  }

  /** handlers for manufacturing capabilities table */
  const handleCurrPlant = ({ currPlant, plantIndex } : { currPlant: IPlantMachineDetail, plantIndex: number }) => {
    setCurrPlantObj(currPlant);
    setIndexOfCurrPlant(plantIndex);
  };

  const handleCurrMachine = ({ machine: currMachine, machineIndex, plantIndex: currPlantIndex }: IMachineHandlerArgType) => {
    const currPlant = plantMachineDetails?.find((_, idx) => idx === currPlantIndex) || {};
    setCurrPlantObj(currPlant);
    setIndexOfCurrPlant(currPlantIndex);
    setCurrMachineObj(currMachine);
    setIndexOfCurrMachine(machineIndex);
  };

  const handleCloseManufacturingCapabilitiesModals = () => {
    setCurrPlantObj({});
    setCurrMachineObj({});
    setShowAddOrEditPlantModal(false);
    setShowAddOrEditMachineModal(false);
    setIndexOfCurrMachine(null);
    setIndexOfCurrPlant(null);
    setIsFormAddNewOrEditExisting(null);
  };

  const handleRemovePlant = (args: IPlantHandlerArgType) => {
    const { plantDetail, plantIndex } = args;
    let updatedPlantMachineDetails: typeof plantMachineDetails  = [];
    /** remove plant */
    for (let i = 0; i < plantMachineDetails?.length; i++) {
      const existingPlant = plantMachineDetails[i];
      if (existingPlant?._id) {
        /** Plant Present in DB */
        if (existingPlant?._id === plantDetail?._id) {
          /** mark existing plant as delete */
          existingPlant["delete"] = true;
          updatedPlantMachineDetails.push(existingPlant);
        } else {
          updatedPlantMachineDetails.push(existingPlant);
        }
      } else {
        /** new plant present only in UI */
        if (i === plantIndex) {
          /** remove existing plant */
          continue;
        } else {
          /** add existing plant to updated list in UI */
          updatedPlantMachineDetails.push(existingPlant);
        }
      }
    }

    manageIsPlantMachinePresent(updatedPlantMachineDetails);
    setPlantMachineDetails(updatedPlantMachineDetails);
    toggleIsPageEdited(dispatch, true);
  };

  /** remove machine from selected plant */
  const handleRemoveMachine = (args: IMachineHandlerArgType) => {
    const { machine, machineIndex, plantIndex } = args;

    let updatedPlantMachineDetails: typeof plantMachineDetails  = [];
    for (let i = 0; i < plantMachineDetails?.length; i++) {
      const existingPlant = plantMachineDetails[i];
      const isSelectedPlant = i === plantIndex;

      let updatedMachines: typeof existingPlant["machines"] = [];
      for (let j = 0; j < existingPlant?.machines?.length; j++) {
        const existingMachine = existingPlant?.machines[j];
        if (machine?._id && existingMachine?._id) {
          if (isSelectedPlant && machine?._id === existingMachine?._id) {
            /** mark Machine Present in DB as delete */
            existingMachine["delete"] = true;
            updatedMachines.push(existingMachine);
          } else {
            updatedMachines.push(existingMachine);
          }
        } else {
          /** new machine present only in UI */
          if (isSelectedPlant && j === machineIndex) {
            /** exclude new machine present only in UI */
            continue;
          } else {
            updatedMachines.push(existingMachine);
          }
        }
      }

      /** update machines in existing plant */
      plantMachineDetails[i]["machines"] = updatedMachines;

      /** add existing plant to updated list in UI */
      updatedPlantMachineDetails.push(existingPlant);
    }

    setPlantMachineDetails(updatedPlantMachineDetails);
    toggleIsPageEdited(dispatch, true);
  };

  const manufacturingCapabilitiesHandler: IManufCapabilitiesTableProps["handlers"] = {
    plant: {
      handleOnClickAddNewPlant: ({ plantDetail, plantIndex }) => {
        handleCurrPlant({ currPlant: plantDetail, plantIndex });
        setShowAddOrEditPlantModal(true);
        setIsFormAddNewOrEditExisting("ADD_NEW");
      },
      handleOnClickEditPlant: ({ plantDetail, plantIndex }) => {
        handleCurrPlant({ currPlant: plantDetail, plantIndex });
        setShowAddOrEditPlantModal(true);
        setIsFormAddNewOrEditExisting("EDIT_EXISTING");
        heapTrackTrigger({
          windowObj: window,
          value: {},
          eventName: HEAP_DATA_ATTRIBUTES.CAPABILITIES.MANUFACTURING_CAPABILITIES.HEAP_TRACK.PLANT.TABLE.EDIT,
        });
      },
      handleOnClickDeletePlant: ({ plantDetail, plantIndex }) => {
        handleCurrPlant({ currPlant: plantDetail, plantIndex });
        handleRemovePlant({ plantDetail, plantIndex });
        heapTrackTrigger({
          windowObj: window,
          value: {},
          eventName: HEAP_DATA_ATTRIBUTES.CAPABILITIES.MANUFACTURING_CAPABILITIES.HEAP_TRACK.PLANT.TABLE.DELETE,
        });
      },
    },
    machine: {
      handleOnClickAddNewMachine: ({ machine, machineIndex, plantIndex }) => {
        handleCurrMachine({
          machine: {} as IMachine,
          machineIndex: machineIndex,
          plantIndex: plantIndex,
        });
        setShowAddOrEditMachineModal(true);
        setIsFormAddNewOrEditExisting("ADD_NEW");
        heapTrackTrigger({
          windowObj: window,
          value: {},
          eventName: HEAP_DATA_ATTRIBUTES.CAPABILITIES.MANUFACTURING_CAPABILITIES.HEAP_TRACK.MACHINE.TABLE.ADD_NEW,
        });
      },
      handleOnClickEditMachine: ({ machine, machineIndex, plantIndex }) => {
        handleCurrMachine({ machine, machineIndex, plantIndex });
        setShowAddOrEditMachineModal(true);
        setIsFormAddNewOrEditExisting("EDIT_EXISTING");
        heapTrackTrigger({
          windowObj: window,
          value: {},
          eventName: HEAP_DATA_ATTRIBUTES.CAPABILITIES.MANUFACTURING_CAPABILITIES.HEAP_TRACK.MACHINE.TABLE.EDIT,
        });
      },
      handleOnClickDeleteMachine: ({ machine, machineIndex, plantIndex }) => {
        handleCurrMachine({ machine, machineIndex, plantIndex });
        handleRemoveMachine({ machine, machineIndex, plantIndex });
        heapTrackTrigger({
          windowObj: window,
          value: {},
          eventName: HEAP_DATA_ATTRIBUTES.CAPABILITIES.MANUFACTURING_CAPABILITIES.HEAP_TRACK.MACHINE.TABLE.DELETE,
        });
      },
      // only required in VPV
      // handleOnClickViewMachineGallery: (args) => {},
    },
  };

  const renderAddNewPlantButton = () => {
    return (
      <VZuiButton
        width={"max-content"}
        height={"30px"}
        variant={"primary"}
        colorSchema="blue"
        style={{
          padding: "0px 12px",
          backgroundColor: colors.blue,
          borderColor: colors.blue,
        }}
        onClick={() => {
          manufacturingCapabilitiesHandler?.plant?.handleOnClickAddNewPlant({
            plantDetail: {} as IPlantMachineDetail, 
            plantIndex: plantMachineDetails?.length, // index greater than the last index
          });
        }}
        {...{
          [HEAP_DATA_TRACKING_ID]: 
            HEAP_DATA_ATTRIBUTES.CAPABILITIES.MANUFACTURING_CAPABILITIES.ON_CLICK.ADD_NEW_PLANT,
        }}
      >
        <Typography
          width={"max-content"}
          variant={"span"}
          fontWeight={600}
          fontSize={"14px"}
          color={colors.white}
          cursor="pointer"
        >
          Add New Plant
        </Typography>
      </VZuiButton>
    );
  };

  return (
    <>
      <Box width={{ default: "100%", sm: "75vw" }}>
        <CapabilitiesModal
          headerName={modalData.headerName}
          type={modalData.type}
          data={modalData?.data}
          visibility={modalOpenState}
          onClose={() => {
            setModalOpenState(false);
          }}
          onHandleScopeOfWorkChange={(e) => {
            setSelectedWork(e);
            toggleIsPageEdited(dispatch, true);
          }}
          onHandleStatesChange={(isChecked, value) => {
            if (isChecked) {
              renderState(value);
              toggleIsPageEdited(dispatch, true);
            } else {
              setStateCardData(
                stateCardData.filter((item) => {
                  return item !== value;
                })
              );
            }
          }}
        ></CapabilitiesModal>
        <ContentInputs>
          <FormWrapper>
            <DropdownWithTagDiv id="industryServed">
              <FormField
                isStar
                label="Industries Served"
                info="Industries of customers served in last 5 years (multiple select)"
                showError={saveState && !industriesServed?.length}
                isPaddingNotRequired={true}
                dataTrackingId={
                  HEAP_DATA_ATTRIBUTES.CAPABILITIES.DROPDOWN.INDUSTRIES_SERVED
                }
              >
                <MultiSelectDropdown
                  aria-expanded="true"
                  error={
                    saveState &&
                    !industriesServed?.length &&
                    !checkForMobileScreen()
                      ? onHandleScroll("industryServed")
                      : false
                  }
                  overrideStrings={DropdownData.overRideString}
                  hasSelectAll={false}
                  options={DropdownData.industries}
                  value={industriesServed?.length ? industriesServed : []}
                  ArrowRenderer={() => {
                    return (
                      <img
                        style={{ marginRight: "10px" }}
                        src="/images/DashBoard/arrow-down.svg"
                      />
                    );
                  }}
                  onChange={(e) => {
                    setIndustriesServed(e);
                    toggleIsPageEdited(dispatch, true);
                  }}
                  labelledBy=""
                />
              </FormField>
              <TagContainer>
                {industriesServed?.map((item) => {
                  return (
                    <CardTag
                      onClose={() => closeCard("Close_Industries_Served", item)}
                      usedFor="selectItem"
                      cardText={item.value}
                      closable={false}
                      height="40px"
                      labelFontVariant="medium"
                      labelFontColor="#215EC9"
                    />
                  );
                })}
              </TagContainer>
              {otherWorkState && (
                <>
                  <InputCardIconWrapper>
                    <InputWithIcon
                      inputBoxSize="large"
                      usedFor="profileCreation"
                      importanceType="important"
                      buttonOnClick={(e: any) => {
                        addNatureOfWorkTags(e);
                      }}
                      placeholder="Enter nature of work for others"
                      onHandleChange={(e: any) => {
                        handleNatureOfWorkChange(e);
                        toggleIsPageEdited(dispatch, true);
                      }}
                      withHeader={false}
                    />
                  </InputCardIconWrapper>

                  <TagContainer>
                    {natureOfWork?.selectedData?.map((item) => {
                      return (
                        <CardTag
                          onClose={() =>
                            closeCard("Close_Nature_Of_Work_Add", item)
                          }
                          usedFor="selectItem"
                          cardText={item}
                          closable={false}
                          height="40px"
                          labelFontVariant="medium"
                          labelFontColor="#215EC9"
                        />
                      );
                    })}
                  </TagContainer>
                </>
              )}
            </DropdownWithTagDiv>

            <DropdownWithTagDiv id="natureOfWork">
              <FormField
                isStar
                label="Scope of Work"
                info="Scope of services offered (multiple select)"
                showError={saveState && !selectedWork?.length}
                isPaddingNotRequired={true}
                dataTrackingId={
                  HEAP_DATA_ATTRIBUTES.CAPABILITIES.DROPDOWN.SCOPE_OF_WORK
                }
              >
                <FlexColumn onClick={handleScopeOfWOrkOnClick}>
                  <MultiSelectDropdown
                    error={
                      saveState && !selectedWork?.length
                        ? onHandleScroll("natureOfWork")
                        : false
                    }
                    overrideStrings={Work.overRideString}
                    options={Work.states}
                    value={selectedWork}
                    hasSelectAll={false}
                    ArrowRenderer={() => {
                      return (
                        <img
                          style={{ marginRight: "10px" }}
                          src="/images/DashBoard/arrow-down.svg"
                        />
                      );
                    }}
                    onChange={(e) => {
                      setSelectedWork(e);
                      toggleIsPageEdited(dispatch, true);
                    }}
                    labelledBy="Select Scope of Work"
                  />
                  <TagContainer $hideOnMobileScreen={true}>
                    {selectedWork?.map((item) => {
                      return (
                        <CardTag
                          onClose={() => closeCard("Close_Nature_Of_Work", item)}
                          usedFor="selectItem"
                          cardText={item.value}
                          closable={false}
                          height="40px"
                          labelFontVariant="medium"
                          labelFontColor="#215EC9"
                        />
                      );
                    })}
                  </TagContainer>

                  {otherWorkState && (
                    <>
                      <InputCardIconWrapper>
                        <InputWithIcon
                          inputBoxSize="large"
                          usedFor="profileCreation"
                          importanceType="important"
                          buttonOnClick={(e: any) => {
                            addNatureOfWorkTags(e);
                          }}
                          placeholder="Enter nature of work for others"
                          onHandleChange={(e: any) => {
                            handleNatureOfWorkChange(e);
                            toggleIsPageEdited(dispatch, true);
                          }}
                          withHeader={false}
                        />
                      </InputCardIconWrapper>

                      <TagContainer>
                        {natureOfWork?.selectedData?.map((item) => {
                          return (
                            <CardTag
                              onClose={() =>
                                closeCard("Close_Nature_Of_Work_Add", item)
                              }
                              usedFor="selectItem"
                              cardText={item}
                              closable={false}
                              height="40px"
                              labelFontVariant="medium"
                              labelFontColor="#215EC9"
                            />
                          );
                        })}
                      </TagContainer>
                    </>
                  )}
                </FlexColumn>
              </FormField>
            </DropdownWithTagDiv>

            <LocationServedDiv id="locationServed">
              <LocationWrapper>
                <DropdownWithTagDiv>
                  <FormField
                    isStar
                    label="Locations served in India"
                    info="Select locations served in last 5 years (multiple select)"
                    showError={saveState && !stateCardData?.length}
                    isPaddingNotRequired={true}
                    dataTrackingId={
                      HEAP_DATA_ATTRIBUTES.CAPABILITIES.DROPDOWN.INDIAN_LOCATIONS
                    }
                  >
                    <LocationContentWrapper onClick={handleStatesOnClick}>
                      <DropdownWithHeader
                        paddingNotRequired
                        error={
                          saveState && !stateCardData?.length
                            ? onHandleScroll("locationServed")
                            : false
                        }
                        disabled={false}
                        hide={true}
                        placeholder={"Select States/ UTs"}
                        sizes="medium"
                        data={stateDropdownDetails?.map((item, index) => {
                          return { id: index, value: item };
                        })}
                        onChange={(element: string) => {
                          renderState(element);
                          toggleIsPageEdited(dispatch, true);
                        }}
                        onSearch={(element: string) => {
                          getState(element);
                        }}
                        usedFor="profileCreation"
                        headerFlag={false}
                        buttonChild={false}
                        onButtonClick={() => {
                          if (locationServed && locationServed?.length > 0) {
                            setLocationServed(
                              locationServed?.filter((item) => {
                                return item?.state.splice(0);
                              })
                            );
                          }
                          setSelectedState(undefined);
                          toggleIsPageEdited(dispatch, true);
                        }}
                        value={
                          checkForMobileScreen() && stateCardData?.length
                            ? stateCardData.join(",")
                            : "Select States/ UTs"
                        }
                        height="40px"
                      ></DropdownWithHeader>
                    </LocationContentWrapper>
                  </FormField>
                  <StateServedTagContainer $hideOnMobileScreen={true}>
                    {stateCardData?.map((item, index) => {
                      return (
                        <CardLocationTag
                          key={index}
                          onClose={() => {
                            closeCard("Close_State", item);
                          }}
                          cardText={item}
                          usedFor="selectItem"
                          closable={false}
                          labelFontVariant="medium"
                          labelFontColor="#215EC9"
                        />
                      );
                    })}
                  </StateServedTagContainer>
                </DropdownWithTagDiv>
                <DropdownWithTagDiv id="locationsServedWorldwide">
                  <ProfileInputHeading
                    isStar={false}
                    name="Locations served worldwide"
                    isPaddingNotRequired={true}
                  />
                  <LocationContentWrapper>
                    <DropdownWithHeader
                      paddingNotRequired
                      onBlurTrigger={() => {
                        setCountryList([]);
                      }}
                      onBlurEventRequired
                      hide={true}
                      placeholder="Select Countries"
                      sizes="medium"
                      data={countryList?.map((item, index) => {
                        return { id: index, value: item.name };
                      })}
                      onChange={(element: string) => {
                        renderCountry(element);
                        toggleIsPageEdited(dispatch, true);
                      }}
                      onSearch={(element: string) => {
                        setSelectedCountry("");
                        getCountry(element);
                      }}
                      usedFor="profileCreation"
                      headerFlag={false}
                      buttonChild={false}
                      onButtonClick={() => {
                        setLocationServed([]);
                      }}
                      value={"Select Countries"}
                      height="40px"
                    ></DropdownWithHeader>
                  </LocationContentWrapper>
                  <StateServedTagContainer>
                    {countryCardData?.map((item, index) => {
                      return (
                        <CardLocationTag
                          key={index}
                          onClose={() => {
                            closeCard("Close_Country", item);
                          }}
                          cardText={item}
                          usedFor="selectItem"
                          closable={false}
                          labelFontVariant="medium"
                          labelFontColor="#215EC9"
                        />
                      );
                    })}
                  </StateServedTagContainer>
                </DropdownWithTagDiv>
              </LocationWrapper>
            </LocationServedDiv>

            <DropdownWithTagDiv id="keyCustomer">
              <KeyCustomer style={{ display: "flex" }}>
                <TextAreaWrapper>
                  <FormField
                    data-capabilities-key-customer="true"
                    isStar
                    label="Key Customers"
                    info={
                      checkForMobileScreen()
                        ? ""
                        : "Add as text or upload list of your key customers"
                    }
                    showError={
                      saveState &&
                      !customerListFile?.length &&
                      !keyCustomerDesc?.length
                    }
                    isPaddingNotRequired={true}
                  >
                    <TextAreaView className="TextAreaDesktopView">
                      {renderKeyCustomerUI()}
                    </TextAreaView>
                    <TextAreaView className="TextAreaMobileView">
                      {renderKeyCustomerMobileUI()}
                    </TextAreaView>
                  </FormField>
                </TextAreaWrapper>
                {customerListFile?.length || tempDoc?.length ? (
                  <KeyCustomerFileRespView $hideOnMobileScreen>
                    {renderKeyCustomerCertificate()}
                  </KeyCustomerFileRespView>
                ) : (
                  <InputFlexWrapper>
                    <InputDivWrap style={{ height: "40px" }}>
                      <Button
                        onClick={() => {}}
                        size="small"
                        style={{
                          width: "280px",
                          height: "40px",
                          background: "#F4F6FF",
                          borderColor: "#D4E5FE",
                          borderRadius: "8px",
                          color: "#215EC9",
                          fontWeight: 600,
                          fontSize: "14px",
                        }}
                      >
                        <UploadIcon src="/images/upload.svg" alt="upload" />
                        Upload Customer List
                        <InputWrap
                          type="file"
                          onChange={(e) => getFile(e, "CUSTOMER")}
                          accept="application/pdf,image/jpeg,.xml,.xlsx,.xls,.docx"
                          disabled={false}
                          style={{ width: "280px", height: "40px" }}
                          data-tracking-id={
                            HEAP_DATA_ATTRIBUTES.CAPABILITIES.UPLOAD.CUSTOMER_LIST
                          }
                        />
                      </Button>
                    </InputDivWrap>
                    <WarningMsg style={{ width: "210px" }}>
                      <Div>
                        <LogoImage
                          imageSrc="/images/ProfileCreation/info.svg"
                          altMesg="edit button"
                          imageType="icon"
                        />
                      </Div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Label
                          fontVariant="bold"
                          htmlFor="brochureSize"
                          text="Supports .xls, .pdf, .docx, .jpeg,
                          .png formats."
                        />
                      </div>
                    </WarningMsg>
                  </InputFlexWrapper>
                )}
              </KeyCustomer>
            </DropdownWithTagDiv>

            <TextAreaWrapper id="serviceOffering">
              <FormField
                isStar
                label="Service Offerings"
                info={
                  checkForMobileScreen()
                    ? ""
                    : "Describe service offerings with capacity range handled.Also mention key OEMs catered if any"
                }
                showError={
                  saveState && (IS_SERVICE_DESC_ERROR)
                }
                isPaddingNotRequired={true}
              >
                {/* Commented for future reference */}
                {/* <TextAreaDiv
                  data-tracking-id={
                    HEAP_DATA_ATTRIBUTES.CAPABILITIES.SERVICE_OFFERINGS
                  }
                  placeholder={
                    checkForMobileScreen()
                      ? "Enter Service Description"
                      : `Eg:\n1. Turnkey supply and maintenance of FRP cooling Towers\nfrom 5 TR TO 5000 TR capacity`
                  }
                  onChange={(e) => {
                    handleTextChange(e.target.value, "service");
                    toggleIsPageEdited(dispatch, true);
                  }}
                  value={serviceDesc}
                  error={
                    saveState && !serviceDesc?.length
                      ? onHandleScroll("serviceOffering")
                      : false
                  }
                ></TextAreaDiv> */}
                {/* Rich Text Editor */}
                <CapabilitiesCustomReactQuillEditorWrapper
                  isError={saveState && IS_SERVICE_DESC_ERROR}
                >
                  <ReactQuill
                    theme="snow"
                    preserveWhitespace
                    modules={modules}
                    value={serviceDesc || ""}
                    style={reactQuillCustomStyles({
                      isError: saveState && IS_SERVICE_DESC_ERROR,
                    })}
                    data-tracking-id={
                      HEAP_DATA_ATTRIBUTES.CAPABILITIES.SERVICE_OFFERINGS
                    }
                    placeholder={
                      checkForMobileScreen()
                        ? "Enter Service Description"
                        : `Eg:\n1. Turnkey supply and maintenance of FRP cooling Towers\nfrom 5 TR TO 5000 TR capacity`
                    }
                    onChange={(value) => {
                      let formattedValue = cleanFormatting({
                        content: value,
                        stateVal: serviceDesc,
                      });

                      if (
                        formattedValue === VISUALLY_EMPTY_STRING
                        || DIV_WITH_EMPTY_SPACES_PATTERN.test(value)
                      ) {
                        /** When:
                         * 1. user clears everything in the textarea, visually it looks empty, 
                         *    but the value is not empty, it contains the string value of VISUALLY_EMPTY_STRING
                         * 2. after the above step if the user enters empty spaces, 
                         *    then reset the state value to empty string
                         * 
                         * In the above cases reset the value to empty string & show error,
                         * because service description is a required field
                         */
                        handleTextChange("", "service");
                      } else if (formattedValue !== serviceDesc) {
                        handleTextChange(formattedValue, "service");
                        toggleIsPageEdited(dispatch, true);
                      } else {
                        toggleIsPageEdited(dispatch, false);
                      }
                    }}
                    onBlur={() => {
                      if (IS_SERVICE_DESC_ERROR) onHandleScroll("serviceOffering");
                    }}
                  />
                </CapabilitiesCustomReactQuillEditorWrapper>
              </FormField>
            </TextAreaWrapper>

            <AdditionalInfoWrapper>
              <span>Additional Information</span>
              <p>
                Additional information like certifications and OEM partnerships
                enhance your profile.
              </p>
              <AddManuallyBttn
                onClick={() => {
                  isFirstError = true;
                  handleClick("save", "mobileUser");
                }}
                $width={"100%"}
                $borderRadius={"8px"}
                $height={"40px"}
              >
                + Add additional information
              </AddManuallyBttn>
            </AdditionalInfoWrapper>
            
            <CertificatesWrapper>{renderCertificates()}</CertificatesWrapper>

            {/* Image Upload Section */}
            <DropdownWithTagDiv id="imageUpload" style={{ marginBottom: "8px", marginTop: "40px" }}>
              <FormField
                isStar={false}
                label="Image Gallery"
                showError={false}
                isPaddingNotRequired={true}
              >
                <Flex flexDirection="column" gap="16px">
                  <Typography
                    variant="h5"
                    style={{
                      color: "background: rgba(1, 23, 89, 1)",
                      fontWeight: 400,
                    }}
                  >
                    Upload images of products and factory in .png, .jpg, .jpeg format.
                    Maximum file size 20 MB
                  </Typography>
                  <Flex gap="16px" flexWrap="wrap">
                    <BasicDropdown
                      sizes="medium"
                      placeholder="Select Category"
                      data={DropdownData.galleryData}
                      onChange={(element: string) => {
                        setSelectedImageCategory(element);
                      }}
                      usedFor="image_video_upload"
                      height="40px"
                      value={selectedImageCategory || undefined}
                    />
                    <Button
                      onClick={() => {}}
                      size="small"
                      style={{
                        width: "280px",
                        height: "40px",
                        background: "#F4F6FF",
                        borderColor: "#D4E5FE",
                        borderRadius: "8px",
                        color: "#215EC9",
                        fontWeight: 600,
                        fontSize: "14px",
                        marginRight: "10px",
                      }}
                      $mobileScreenStyles={{
                        width: "100% !important",
                        borderRadius: "8px",
                      }}
                    >
                      <UploadIcon src="/images/upload.svg" alt="upload" />
                      {selectedImageCategory
                        ? `${selectedImageCategory} Images`
                        : "Upload Image"}
                      <InputWrap
                        type="file"
                        onChange={handleImageUpload}
                        accept="image/jpeg,.png,.jpg,.webp"
                        disabled={false}
                        style={{ width: "280px", height: "40px" }}
                        multiple
                      />
                    </Button>
                  </Flex>
                  {/* Image thumbnail with x mark in right edge of it */}
                  <Flex gap="16px" flexWrap="wrap" alignItems="center" paddingTop={"4px"}>
                    {galleryData?.map((image: any, index: number) => {
                      const IMG_URL = image?.file?.fileUrl;
                      const handleShowCurrImgInGalleryModal = () => {
                        const INDEX_OF_CURR_IMG = GALLERY_MODAL_DATA?.findIndex((existingImgObj) => {
                          return existingImgObj?.file?.fileUrl === image?.file?.fileUrl;
                        }) || 0;
                        setCurrentIndex(INDEX_OF_CURR_IMG);
                        setViewGalleryModal(true);
                      };

                      const handleRemoveImageFromGallery = () => {
                        /** remove the image from main galleryData */
                        const tempGalleryData = [...galleryData];
                        tempGalleryData.splice(index, 1);
                        setGalleryData(tempGalleryData);

                        /** remove deleted image from machine gallery as well */
                        setPlantMachineDetails(prev => {
                          return prev.map((plant, plantIdx) => {
                            return {
                              ...plant,
                              machines: plant?.machines?.map((machine, machineIdx) => {
                                return {
                                  ...machine,
                                  gallery: machine?.gallery?.filter((imageObj) => {
                                    return imageObj?.file?.fileUrl !== IMG_URL;
                                  })
                                }
                              })
                            }
                          });
                        })
                        toggleIsPageEdited(dispatch, true);
                      };

                      return (
                        <Box position="relative" key={IMG_URL}>
                          <Box
                            position="absolute"
                            top="-8px"
                            right="-8px"
                            background={colors.blue}
                            borderRadius="50%"
                            width="20px"
                            height="20px"
                            cursor="pointer"
                          >
                            <IoClose
                              size="20px"
                              style={{ padding: "2px" }}
                              onClick={handleRemoveImageFromGallery}
                            />
                          </Box>
                          <Flex
                            alignItems="center"
                            justifyContent="center"
                          >
                            <img
                              alt="image"
                              src={IMG_URL}
                              style={{ 
                                width: "auto", 
                                cursor: "pointer",
                                height: isScreenWidthLessThan640px ? "50px" : "70px"
                              }}
                              onClick={() => {
                                setIsCurrentCaptionBeingEdited(false);
                                handleShowCurrImgInGalleryModal();
                              }}
                            />
                          </Flex>
                          {/* Add Caption/Edit Caption Button for Image */}
                          <CaptionButton
                            variant="ghost"
                            colorSchema="blue"
                            onClick={() => {
                              setIsCurrentCaptionBeingEdited(true);
                              handleShowCurrImgInGalleryModal();
                              handleTogglePulsingCaptionInputFieldForGallery(true);
                            }}
                          >
                            <Typography fontSize={"10px"} fontWeight={500} cursor="pointer">
                              {`${image?.caption?.length ? "Edit" : "Add"} Caption`}
                            </Typography>
                          </CaptionButton>
                        </Box>
                      );
                    })}
                    <Spin spinning={isImageUploading} />
                  </Flex>
                </Flex>
              </FormField>
            </DropdownWithTagDiv>

            {/* Video Upload Section */}
            <InputDivWrap
              id="videoUrl"
              style={{ width: "550px", height: "auto" }}
            >
              <FormField
                isStar={false}
                label="Video Gallery"
                showError={false}
                isPaddingNotRequired={true}
              >
                <Flex flexDirection="column" gap="16px">
                  <Typography
                    variant="h5"
                    style={{
                      color: "background: rgba(1, 23, 89, 1)",
                      fontWeight: 400,
                    }}
                  >
                    Upload videos of products and factory in .mp4 format or paste
                    links. Maximum file size 20 MB
                  </Typography>
                  <Flex
                    gap="16px"
                    alignItems="center"
                    flexWrap={{
                      default: "wrap",
                      sm: "nowrap",
                    }}
                  >
                    <BasicDropdown
                      sizes="medium"
                      placeholder="Select Category"
                      data={DropdownData.galleryData}
                      onChange={(element: string) => {
                        setSelectedVideoCategory(element);
                      }}
                      usedFor="image_video_upload"
                      height="40px"
                      value={selectedVideoCategory || undefined}
                    />
                    <Button
                      onClick={() => {}}
                      size="small"
                      style={{
                        width: window.innerWidth > 768 ? "280px" : "100%",
                        height: "40px",
                        background: "#F4F6FF",
                        borderColor: "#D4E5FE",
                        borderRadius: "8px",
                        color: "#215EC9",
                        fontWeight: 600,
                        fontSize: "14px",
                      }}
                    >
                      <UploadIcon src="/images/upload.svg" alt="upload" />
                      {selectedVideoCategory
                        ? `${selectedVideoCategory} Videos`
                        : "Upload Video"}
                      <InputWrap
                        type="file"
                        onChange={handleVideoUpload}
                        accept=".mp4"
                        disabled={false}
                        style={{ width: "280px", height: "40px" }}
                        multiple
                      />
                    </Button>
                    <Typography
                      variant="h5"
                      style={{
                        color: "#215EC9",
                        width: window.innerWidth > 768 ? "max-content" : "100%",
                        textAlign: "center",
                      }}
                    >
                      OR
                    </Typography>
                    <Flex
                      gap="16px"
                      flexWrap={{
                        default: "wrap",
                        sm: "nowrap",
                      }}
                    >
                      <InputWithHeaderForEdit
                        usedFor="profileCreation"
                        inputBoxSize="medium"
                        inputType="text"
                        importance="important"
                        name="videoLink"
                        label="Video Link"
                        placeholder={"Add video link here"}
                        value={typedVideoUrl}
                        onHandleChange={(e) => {
                          setTypedVideoUrl(e.target.value);
                        }}
                        inputBoxStyle={{
                          width: window.innerWidth > 768 ? "280px" : "100%",
                          height: "40px",
                          marginRight: "-10px",
                          fontSize: "14px",
                        }}
                      ></InputWithHeaderForEdit>
                      {/* Save - Button for saving Video Link */}
                      <Button
                        disabled={typedVideoUrl.length === 0}
                        onClick={handleSaveVideoURL}
                        size="small"
                        style={{
                          height: "40px",
                          background: "#F4F6FF",
                          borderColor: "#D4E5FE",
                          borderRadius: "8px",
                          color: "#215EC9",
                          fontWeight: 600,
                          fontSize: "14px",
                          width: window.innerWidth > 768 ? "max-content" : "100%",
                        }}
                      >
                        Save
                      </Button>
                    </Flex>
                  </Flex>
                  {/* Image thumbnail with x mark in right edge of it */}
                  <Flex gap="16px" flexWrap="wrap">
                    {video?.map((value: any, index: number) => {
                      // TODO: add better types
                      const FILE_UPLOAD_EXCEPTION = value?.name === "Connection Error!" || value?.name === "Upload Cancelled!";
                      const FILE_UPLOAD_SUCCESS = value?.percent
                        ? value?.percent >= 100 
                          ? !FILE_UPLOAD_EXCEPTION
                          : false
                        : true;
                      const handleShowCurrVideoInGalleryModal = () => {
                        const INDEX_OF_CURR_VIDEO = GALLERY_MODAL_DATA?.findIndex((existingVideoObj) => {
                          if (existingVideoObj?.file?.fileUrl) return existingVideoObj?.file?.fileUrl === value?.file?.fileUrl;
                          return existingVideoObj?.videoUrl === value?.videoUrl;
                        }) || 0;
                        setCurrentIndex(INDEX_OF_CURR_VIDEO);
                        setIsCurrentCaptionBeingEdited(true);
                        setViewGalleryModal(true);
                      };

                      const handleRemoveVideoFromGallery = () => {
                        /** remove deleted video from machine gallery as well */
                        setPlantMachineDetails(prev => {
                          return prev.map((plant, plantIdx) => {
                            return {
                              ...plant,
                              machines: plant?.machines?.map((machine, machineIdx) => {
                                return {
                                  ...machine,
                                  gallery: machine?.gallery?.filter((videoObj: { videoUrl?: string, file?: { fileUrl: string } }) => {
                                    if (videoObj?.file?.fileUrl && value?.file?.fileUrl) return videoObj?.file?.fileUrl !== value?.file?.fileUrl;
                                    return videoObj?.videoUrl !== value?.videoUrl;
                                  })
                                }
                              })
                            }
                          });
                        })

                        //change the name to delete
                        setVideo((prev: any) => {
                          const temp = [...prev];
                          //if the file has a percent
                          if (
                            temp[index].percent &&
                            temp[index].name !== "Upload Cancelled!" &&
                            temp[index].name !== "Connection Error!"
                          ) {
                            temp[index].name = "Upload Cancelled!";
                            temp[index].percent = 100;
                            toggleIsPageEdited(dispatch, false);
                          } else {
                            temp.splice(index, 1);
                            toggleIsPageEdited(dispatch, true);
                          }
                          return temp;
                        });
                      };

                      return (
                        <Box key={value?.file?.fileUrl || value?.videoUrl || index}>
                          {/* TODO: find a better variable for above key */}
                          <Box width="max-content" position="relative">
                            <Flex
                              gap="16px"
                              border="1px solid rgba(212, 229, 254, 1)"
                              padding="8px"
                              borderRadius="4px"
                            >
                              <a
                                href={value?.file?.fileUrl || value?.videoUrl}
                                target="_blank"
                                style={{
                                  color: "#215EC9",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  maxWidth: "250px",
                                }}
                              >
                                {value?.name}
                              </a>
                              {/* //closable icon */}
                              <IoClose
                                size="20px"
                                color="rgba(212, 229, 254, 1)"
                                onClick={handleRemoveVideoFromGallery}
                              />
                            </Flex>
                            {value?.percent > 0 && (
                              <Progress
                                percent={value?.percent}
                                status={
                                  value?.name === "Connection Error!" ||
                                  value?.name === "Upload Cancelled!"
                                    ? "exception"
                                    : value?.percent === 100
                                    ? "success"
                                    : "active"
                                }
                                size="small"
                                showInfo={false}
                                strokeLinecap="butt"
                                style={{
                                  position: "absolute",
                                  top: "30px",
                                }}
                              />
                            )}
                          </Box>
                          {FILE_UPLOAD_SUCCESS && (
                            <>
                              {/* Add Caption/Edit Caption Button for Image */}
                              <CaptionButton
                                variant="ghost"
                                colorSchema="blue"
                                onClick={() => {
                                  handleShowCurrVideoInGalleryModal();
                                  handleTogglePulsingCaptionInputFieldForGallery(true);
                                }}
                              >
                                <Typography fontSize={"10px"} fontWeight={500} cursor="pointer">
                                  {`${value?.caption?.length ? "Edit" : "Add"} Caption`}
                                </Typography>
                              </CaptionButton>
                            </>
                          )}
                        </Box>
                      );
                    })}
                  </Flex>
                </Flex>
              </FormField>
            </InputDivWrap>

            {/* Manufacturing Capablities Table Container */}
            {((isCapabilitiesLoading === false && loaded === true )
              || (plantMachineDetails && plantMachineDetails?.length === 0)
            ) && (
              <Box marginTop={"20px"}>
                <Flex
                  alignItems={"center"}
                  justifyContent={{ default: "flex-start", md: "space-between" }}
                  gap={"24px"}
                  width={"100%"}
                  paddingRight={"24px"}
                  marginBottom={"22px"}
                >
                  <Typography
                    fontWeight={600}
                    fontSize={"14px"}
                    color={colors.darkBlue}
                  >
                    Plant /Workshop Details
                  </Typography>
                  {isPlantMachineDetailsPresent && renderAddNewPlantButton()}
                </Flex>
                {isPlantMachineDetailsPresent ? (
                  <Box
                    width={"100%"}
                    overflowY="hidden"
                    overflowX="auto"
                  >
                    <ManufCapabilitiesTable 
                      isTableEditable
                      hideMachineGalleryButton={true}
                      defaultOpenPlantAccordions={[0]}
                      plantMachineDetails={plantMachineDetails}
                      handlers={manufacturingCapabilitiesHandler}
                      customStyles={{}}
                    />
                  </Box>
                ) : (
                  <Flex
                    gap="20px"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Empty 
                      description={(
                        <Typography
                          fontWeight={600}
                          fontSize={"14px"}
                          color={colors.darkBlue}
                        >
                          No Plant Machine Details Present
                        </Typography>
                      )}
                    />
                    {renderAddNewPlantButton()}
                  </Flex>
                )}
              </Box>
            )}

          </FormWrapper>
        </ContentInputs>
        <ButtonWrapper $padding={"10px 0px 20px 0px"}>
          <ProfileCreationButtons
            dataAttribute={{
              "data-tracking-id":
                HEAP_DATA_ATTRIBUTES.CAPABILITIES.SAVE_AND_CONTINUE,
            }}
            isloading={isDataSaving || isSaveChangesLoading}
            buttonText={isOnboarding ? "SAVE & CONTINUE" : "SAVE CHANGES"}
            usedAt={"onboarding"}
            pagination={true}
            pageNumber={3}
            state={!buttonState.isPageEdited}
            handleStateChange={(value) => {
              if (value === "cancel") {
                setUserData();
              } else {
                isFirstError = true;
                handleClick(value);
              }
            }}
            style={{
              minWidth: "210px",
              height: "40px",
              borderRadius: "4px",
            }}
          ></ProfileCreationButtons>
        </ButtonWrapper>
      </Box>

      <GalleryModal
        isEditableGallery
        gstn={(getGstn() as string) || ""}
        sowId={capabilitiesData?.sowId || ""}
        galleryData={GALLERY_MODAL_DATA}
        setViewGalleryModal={setViewGalleryModal}
        viewGalleryModal={viewGalleryModal}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        setIsCurrentCaptionBeingEdited={setIsCurrentCaptionBeingEdited}
        isCurrentCaptionBeingEdited={isCurrentCaptionBeingEdited}
        showPulsingCaptionInputFieldBorder={showPulsingCaptionInputFieldBorder}
        setShowPulsingCaptionInputFieldBorder={setShowPulsingCaptionInputFieldBorder}
      />

      {/* Show One Modal at a single time */}
      {showAddOrEditPlantModal ? (
        <AddOrEditPlantModal
          isFormAddNewOrEditExisting={isFormAddNewOrEditExisting}
          indexOfCurrPlant={indexOfCurrPlant}
          indexOfCurrMachine={indexOfCurrMachine}
          showModal={showAddOrEditPlantModal}
          currPlant={currPlantObj}
          currMachine={{}}
          plantMachineDetails={plantMachineDetails}
          setPlantMachineDetails={setPlantMachineDetails}
          handleClose={handleCloseManufacturingCapabilitiesModals}
        />
      ) : showAddOrEditMachineModal ? (
        <AddOrEditMachineModal 
          isFormAddNewOrEditExisting={isFormAddNewOrEditExisting}
          indexOfCurrPlant={indexOfCurrPlant}
          indexOfCurrMachine={indexOfCurrMachine}
          showModal={showAddOrEditMachineModal}
          plantMachineDetails={plantMachineDetails}
          setPlantMachineDetails={setPlantMachineDetails}
          currPlant={currPlantObj}
          currMachine={currMachineObj}
          handleClose={handleCloseManufacturingCapabilitiesModals}
        />
      ) : <></>}

    </>
  );
};
export default CapabilitiesNew;
