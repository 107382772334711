import { prepareSearchableAndFilterableOpenJobsData } from "Request/OpenJobs/openJobs.helpers";

export const GET_PLANT_VISIT_REQUEST =
  "plantVisitRequest/GET_PLANT_VISIT_REQUEST";
export const GET_PLANT_VISIT_FAILURE =
  "plantVisitRequest/GET_PLANT_VISIT_FAILURE";
export const GET_REQUEST_TYPE_LIST_SUCCESS =
  "plantVisitRequest/GET_REQUEST_TYPE_LIST_SUCCESS";

export const CREATE_PLANT_VISIT_REQUEST_SUCCESS =
  "plantVisitRequest/CREATE_PLANT_VISIT_REQUEST_SUCCESS";
export const DELETE_PLANT_VISIT_REQUEST_SUCCESS =
  "plantVisitRequest/DELETE_PLANT_VISIT_REQUEST_SUCCESS";
export const UPDATE_PLANT_VISIT_REQUEST_SUCCESS =
  "plantVisitRequest/UPDATE_PLANT_VISIT_REQUEST_SUCCESS";
export const GET_PLANT_VISIT_REQUEST_SUCCESS =
  "plantVisitRequest/GET_PLANT_VISIT_REQUEST_SUCCESS";
export const GET_PLANT_VISIT_REQUEST_LIST_SUCCESS =
  "plantVisitRequest/GET_PLANT_VISIT_REQUEST_LIST_SUCCESS";
export const GET_NEW_UPDATE_REQUEST_COUNT_SUCCESS =
  "plantVisitRequest/GET_NEW_UPDATE_REQUEST_COUNT_SUCCESS";
export const GET_JOB_SUMMARY_REQUEST = "plantVisitRequest/GET_JOB_SUMMARY_REQUEST";
export const GET_JOB_SUMMARY_SUCCESS = "plantVisitRequest/GET_JOB_SUMMARY_SUCCESS";
export const GET_JOB_SUMMARY_FAILURE = "plantVisitRequest/GET_JOB_SUMMARY_FAILURE";
export const GET_OPEN_JOBS_REQUEST = "plantVisitRequest/GET_OPEN_JOBS_REQUEST";
export const GET_OPEN_JOBS_SUCCESS = "plantVisitRequest/GET_OPEN_JOBS_SUCCESS";
export const GET_OPEN_JOBS_FAILURE = "plantVisitRequest/GET_OPEN_JOBS_FAILURE";
export const OPEN_JOB_PAGE_CHANGE = "openJobPageChange/OPEN_JOB_PAGE_CHANGE";
export const OPEN_JOB_FILTER_OPTIONS = "openJobPageChange/OPEN_JOB_FILTER_OPTIONS";
export const OPEN_JOB_SEARCH_STRING_UPDATE = "openJobPageChange/OPEN_JOB_SEARCH_STRING_UPDATE";
export const OPEN_JOB_POSTED_DATE_STARTDATE = "openJobPageChange/OPEN_JOB_POSTED_DATE_STARTDATE";
export const OPEN_JOB_POSTED_DATE_ENDDATE = "openJobPageChange/OPEN_JOB_POSTED_DATE_ENDDATE";
export const GET_OPEN_JOBS_REQUEST_PUBLIC = "openJobPageChange/GET_OPEN_JOBS_REQUEST_PUBLIC";
export const GET_OPEN_JOBS_SUCCESS_PUBLIC = "openJobPageChange/GET_OPEN_JOBS_SUCCESS_PUBLIC";
export const GET_OPEN_JOBS_FAILURE_PUBLIC = "openJobPageChange/GET_OPEN_JOBS_FAILURE_PUBLIC";
export const GET_OPEN_JOB_SUMMARY_REQUEST_PUBLIC = "openJobPageChange/GET_OPEN_JOB_SUMMARY_REQUEST_PUBLIC";
export const GET_OPEN_JOB_SUMMARY_SUCCESS_PUBLIC = "openJobPageChange/GET_OPEN_JOB_SUMMARY_SUCCESS_PUBLIC";
export const GET_OPEN_JOB_SUMMARY_FAILURE_PUBLIC = "openJobPageChange/GET_OPEN_JOB_SUMMARY_FAILURE_PUBLIC";
export const GET_OPEN_JOBS_LOCATIONS_REQUEST = "openJobPageChange/GET_OPEN_JOBS_LOCATIONS_REQUEST";
export const GET_OPEN_JOBS_LOCATIONS_SUCCESS = "openJobPageChange/GET_OPEN_JOBS_LOCATIONS_SUCCESS";
export const GET_OPEN_JOBS_LOCATIONS_FAILURE = "openJobPageChange/GET_OPEN_JOBS_LOCATIONS_FAILURE";
export const GET_OPEN_JOBS_BANNER_REQUEST = "plantVisitRequest/GET_OPEN_JOBS_BANNER_REQUEST";
export const GET_OPEN_JOBS_BANNER_SUCCESS = "plantVisitRequest/GET_OPEN_JOBS_BANNER_SUCCESS";
export const GET_OPEN_JOBS_BANNER_FAILURE = "plantVisitRequest/GET_OPEN_JOBS_BANNER_FAILURE";
//for non-loggedin users
export const GET_OPEN_JOBS_CATEGORIES_REQUEST = "plantVisitRequest/GET_OPEN_JOBS_CATEGORIES_REQUEST";
export const GET_OPEN_JOBS_CATEGORIES_SUCCESS = "plantVisitRequest/GET_OPEN_JOBS_CATEGORIES_SUCCESS";
export const GET_OPEN_JOBS_CATEGORIES_FAILURE = "plantVisitRequest/GET_OPEN_JOBS_CATEGORIES_FAILURE";
//for loggedin users
export const GET_JOBS_CATEGORIES_REQUEST = "plantVisitRequest/GET_JOBS_CATEGORIES_REQUEST";
export const GET_JOBS_CATEGORIES_SUCCESS = "plantVisitRequest/GET_JOBS_CATEGORIES_SUCCESS";
export const GET_JOBS_CATEGORIES_FAILURE = "plantVisitRequest/GET_JOBS_CATEGORIES_FAILURE";
export const OPEN_JOB_SELECTED_CATEGORY_UPDATE = "openJobPageChange/OPEN_JOB_SELECTED_CATEGORY_UPDATE";

export const GET_OPEN_JOBS_RESOURCES_REQUEST = "plantVisitRequest/GET_OPEN_JOBS_RESOURCES_REQUEST";
export const GET_OPEN_JOBS_RESOURCES_SUCCESS = "plantVisitRequest/GET_OPEN_JOBS_RESOURCES_SUCCESS";
export const GET_OPEN_JOBS_RESOURCES_FAILURE = "plantVisitRequest/GET_OPEN_JOBS_RESOURCES_FAILURE";

const initialState = {
  loading: false,
  requestTypeData: null,
  createRequestData: null,
  updateRequestData: null,
  deleteRequestData: false,
  requestData: null,
  requestListData: null,
  newUpdateRequestCount: null,
  jobsData: {
    jobSummary: {
      noOfJobsPostedThisWeek: 0,
      openJobsCount: 0,
      relevantJobs: 0,
      filteredOpenJobCount: 0
    },
    loading: false,
    data: {
      /** @type {import("Request/OpenJobs/openJobs.interfaces").ISearchableOpenJobsData} */
      openJobs: [],
      relevantJobs: [],
      jobsPostedThisWeek: [],
    },
    error: null,
    bannerData: [],
    filterOptions: { jobType: [], jobLocations: [] },
    appliedFilters: { jobType: [], jobLocations: [], jobCategory: "" },
    currentPage: 1,
    searchString: "",
    postedDate: {
      startDate: "",
      endDate: ""
    },
    jobCategories: [],
    resourcesData: []
  },
};

const plantVisitRequest = (state = initialState, action) => {
  switch (action.type) {
    case GET_REQUEST_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        requestTypeData: action.result,
      };

    case CREATE_PLANT_VISIT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: true,
        createRequestData: action.result,
      };

    case DELETE_PLANT_VISIT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: true,
        deleteRequestData: action.result,
      };

    case UPDATE_PLANT_VISIT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: true,
        updateRequestData: action.result,
      };

    case GET_PLANT_VISIT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: true,
        requestData: action.result,
      };

    case GET_PLANT_VISIT_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        requestListData: action.result,
      };

    case GET_NEW_UPDATE_REQUEST_COUNT_SUCCESS:
      return {
        ...state,
        newUpdateRequestCount: action.result.count,
      };

    case GET_OPEN_JOBS_REQUEST:
      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          loading: true,
        },
      };

    case GET_OPEN_JOBS_SUCCESS:
      const result = action?.result || {};
      let openJobsData = result?.openJobs || [];
      // add relevant jobs array to first of the open jobs array
      if (result?.relevantJobs?.length > 0) {
        openJobsData = result?.relevantJobs?.concat(openJobsData) || [];
      }
      const {
        filterOptions,
        searchableOpenJobs
      } = prepareSearchableAndFilterableOpenJobsData(openJobsData);

      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          loading: false,
          filterOptions,
          data: {
            openJobs: searchableOpenJobs || [],
            relevantJobs: result?.relevantJobs || [],
            jobsPostedThisWeek: result?.jobsPostedThisWeek || [],
          },
        },
      };

    case GET_OPEN_JOBS_FAILURE:
      return {
        ...state,
        jobData: {
          ...state.jobData,
          loading: false,
          error: action.error,
        },
      };

    case GET_JOB_SUMMARY_REQUEST:
      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          loading: true,
        },
      };

    case GET_JOB_SUMMARY_SUCCESS:
      /** @type {typeof initialState.jobsData.jobSummary} */
      const jobSummary = (action?.result || {});
      jobSummary.openJobsCount = Number(jobSummary?.openJobsCount || 0) + Number(jobSummary?.relevantJobs || 0);

      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          loading: false,
          jobSummary: action.result,
        },
      };

    case GET_JOB_SUMMARY_FAILURE:
      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          loading: false,
          error: action.error,
        },
      };

    case OPEN_JOB_PAGE_CHANGE:
      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          currentPage: action.result
        }
      };

    case OPEN_JOB_FILTER_OPTIONS:

      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          appliedFilters: { ...state.jobsData.appliedFilters, jobLocations: action.result }
        }
      };

    case OPEN_JOB_SEARCH_STRING_UPDATE:
      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          searchString: action.result
        }
      };

    case OPEN_JOB_POSTED_DATE_STARTDATE:
      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          postedDate: { ...state.jobsData.postedDate, startDate: action.result }
        }
      };

    case OPEN_JOB_POSTED_DATE_ENDDATE:
      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          postedDate: { ...state.jobsData.postedDate, endDate: action.result }
        }
      };

    case GET_OPEN_JOBS_REQUEST_PUBLIC:
      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          loading: true,
        },
      };

    case GET_OPEN_JOBS_FAILURE_PUBLIC:
      return {
        ...state,
        jobData: {
          ...state.jobData,
          loading: false,
          error: action.error,
        },
      };
    
    case GET_OPEN_JOBS_BANNER_REQUEST:
      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          loading: true,
        },
      };
    
    case GET_OPEN_JOBS_BANNER_SUCCESS:
      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          loading: false,
          bannerData: action.result
        },
      };

    case GET_OPEN_JOBS_BANNER_FAILURE:
      return {
        ...state,
        jobData: {
          ...state.jobData,
          loading: false,
          error: action.error,
        },
      };

    case GET_OPEN_JOBS_SUCCESS_PUBLIC:
      const res = action?.result || {};
      let openJobsDataPublic = res?.openJobs || [];

      const {
        searchableOpenJobsPublic
      } = prepareSearchableAndFilterableOpenJobsData(openJobsDataPublic);

      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          loading: false,
          data: {
            openJobs: searchableOpenJobsPublic || [],
            relevantJobs: res?.relevantJobs || [],
            jobsPostedThisWeek: res?.jobsPostedThisWeek || [],
          },
        },
      };

    case GET_OPEN_JOB_SUMMARY_SUCCESS_PUBLIC:
      /** @type {typeof initialState.jobsData.jobSummary} */
      const openJobSummary = (action?.result || {});
      openJobSummary.openJobsCount = Number(openJobSummary?.openJobsCount || 0) + Number(openJobSummary?.relevantJobs || 0);

      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          loading: false,
          jobSummary: openJobSummary,
        },
      };

    case GET_OPEN_JOB_SUMMARY_FAILURE_PUBLIC:
      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          loading: false,
          error: action.error,
        },
      };

    case GET_OPEN_JOB_SUMMARY_REQUEST_PUBLIC:
      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          loading: true,
        },
      };

    case GET_OPEN_JOBS_LOCATIONS_SUCCESS:
      const jobLocations = (action?.result || {});

      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          loading: false,
          filterOptions: { ...state.jobsData.filterOptions, jobLocations: jobLocations },
        },
      };

    case GET_OPEN_JOBS_LOCATIONS_FAILURE:
      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          loading: false,
          error: action.error,
        },
      };

    case GET_OPEN_JOBS_LOCATIONS_REQUEST:
      return {
        ...state,
        jobsData: {
          ...state.jobsData,
          loading: true,
        },
      };

      case GET_OPEN_JOBS_CATEGORIES_REQUEST:
        return {
          ...state,
          jobsData: {
            ...state.jobsData,
            loading: true,
          },
        };
      
      case GET_OPEN_JOBS_CATEGORIES_SUCCESS:
        const finalJobCategoriesArray = action.result.finalCategories;
        finalJobCategoriesArray.unshift("All");

        return {
          ...state,
          jobsData: {
            ...state.jobsData,
            loading: false,
            jobCategories: finalJobCategoriesArray
          },
        };
  
      case GET_OPEN_JOBS_CATEGORIES_FAILURE:
        return {
          ...state,
          jobData: {
            ...state.jobData,
            loading: false,
            error: action.error,
          },
        };
  
      case GET_JOBS_CATEGORIES_REQUEST:
        return {
          ...state,
          jobsData: {
            ...state.jobsData,
            loading: true,
          },
        };
      
      case GET_JOBS_CATEGORIES_SUCCESS:
        const finalVendorJobCategories = action.result.finalCategories;
        finalVendorJobCategories.unshift("All");

        return {
          ...state,
          jobsData: {
            ...state.jobsData,
            loading: false,
            jobCategories: finalVendorJobCategories
          },
        };
  
      case GET_JOBS_CATEGORIES_FAILURE:
        return {
          ...state,
          jobData: {
            ...state.jobData,
            loading: false,
            error: action.error,
          },
        };

      case OPEN_JOB_SELECTED_CATEGORY_UPDATE:
        return {
          ...state,
          jobsData: {
            ...state.jobsData,
            appliedFilters: { ...state.jobsData.appliedFilters, jobCategory: action.result }
        }
      };

      case GET_OPEN_JOBS_RESOURCES_REQUEST:
        return {
          ...state,
          jobsData: {
            ...state.jobsData,
            loading: true,
          },
        };
      
      case GET_OPEN_JOBS_RESOURCES_SUCCESS:
        return {
          ...state,
          jobsData: {
            ...state.jobsData,
            loading: false,
            resourcesData: action.result
          },
        };
  
      case GET_OPEN_JOBS_RESOURCES_FAILURE:
        return {
          ...state,
          jobData: {
            ...state.jobData,
            loading: false,
            error: action.error,
          },
        };  
  
    default:
      return state;
  }
};
export default plantVisitRequest;
//--------------------------------------------------//
export const requestTypes = () => ({
  types: [
    GET_PLANT_VISIT_REQUEST,
    GET_REQUEST_TYPE_LIST_SUCCESS,
    GET_PLANT_VISIT_FAILURE,
  ],
  promise: client => client.get("get_request_type_list", {}),
});
export const createRequest = data => ({
  types: [
    GET_PLANT_VISIT_REQUEST,
    CREATE_PLANT_VISIT_REQUEST_SUCCESS,
    GET_PLANT_VISIT_FAILURE,
  ],
  promise: client => client.post("post_request_create", { data }),
});
export const deleteRequest = (data) => ({
  types: [
    GET_PLANT_VISIT_REQUEST,
    ,
    DELETE_PLANT_VISIT_REQUEST_SUCCESS,
    GET_PLANT_VISIT_FAILURE,
  ],
  promise: client => client.post(`post_request_delete`, { data }),
});
export const updateRequest = data => ({
  types: [
    GET_PLANT_VISIT_REQUEST,
    UPDATE_PLANT_VISIT_REQUEST_SUCCESS,
    GET_PLANT_VISIT_FAILURE,
  ],
  promise: client => client.post("post_request_update", { data }),
});
export const getRequest = requestId => ({
  types: [
    GET_PLANT_VISIT_REQUEST,
    GET_PLANT_VISIT_REQUEST_SUCCESS,
    GET_PLANT_VISIT_FAILURE,
  ],
  promise: client => client.get(`get_request/${requestId}?sender=vendor`, {}),
});
export const getRequestList = data => ({
  types: [
    GET_PLANT_VISIT_REQUEST,
    GET_PLANT_VISIT_REQUEST_LIST_SUCCESS,
    GET_PLANT_VISIT_FAILURE,
  ],
  promise: client => client.get(`get_request_list/${data}&requested_by_type=vendor`, {}),
});

export const getNewUpdateRequestCount = (requestId) => ({
  types: [GET_PLANT_VISIT_REQUEST, GET_NEW_UPDATE_REQUEST_COUNT_SUCCESS, GET_PLANT_VISIT_FAILURE],
  promise: (client) => client.get(`get_new_update_request_count/vendor?id=${requestId}`, {}),
})

export const getJobSummary = () => ({
  types: [
    GET_JOB_SUMMARY_REQUEST,
    GET_JOB_SUMMARY_SUCCESS,
    GET_JOB_SUMMARY_FAILURE,
  ],
  promise: (client) => client.get("get_job_summary", {})

});

export const getOpenJobsList = () => ({
  types: [
    GET_OPEN_JOBS_REQUEST,
    GET_OPEN_JOBS_SUCCESS,
    GET_OPEN_JOBS_FAILURE,
  ],
  promise: (client) => client.get("get_open_jobs", {})
});

export const getOpenJobSummaryPublic = (data) => ({
  types: [
    GET_OPEN_JOB_SUMMARY_REQUEST_PUBLIC,
    GET_OPEN_JOB_SUMMARY_SUCCESS_PUBLIC,
    GET_OPEN_JOB_SUMMARY_FAILURE_PUBLIC,
  ],
  promise: (client) => client.post("get_job_summary_public", {data}),
})

export const getOpenJobsListPublic = (data) => ({
  types: [
    GET_OPEN_JOBS_REQUEST_PUBLIC,
    GET_OPEN_JOBS_SUCCESS_PUBLIC,
    GET_OPEN_JOBS_FAILURE_PUBLIC,
  ],
  promise: (client) => client.post("get_open_jobs_public", { data }),
})

export const getOpenJobsLocations = (data) => ({
  types: [
    GET_OPEN_JOBS_LOCATIONS_REQUEST,
    GET_OPEN_JOBS_LOCATIONS_SUCCESS,
    GET_OPEN_JOBS_LOCATIONS_FAILURE,
  ],
  promise: (client) => client.post("get_open_jobs_locations", { data }),
})

export const updateJobLocation = (jobLocation) => {
  return {
    type: OPEN_JOB_FILTER_OPTIONS,
    result: jobLocation
  }
};

export const updateCurrentPageIndex = (Index) => {
  return {
    type: OPEN_JOB_PAGE_CHANGE,
    result: Index
  }
};

export const updateSearchString = (searchString) => {
  return {
    type: OPEN_JOB_SEARCH_STRING_UPDATE,
    result: searchString
  }
};

export const updateJobCategory = (Category) => {
  return {
    type: OPEN_JOB_SELECTED_CATEGORY_UPDATE,
    result: Category
  }
};

export const updateStartDate = (startDate) => {
  return {
    type: OPEN_JOB_POSTED_DATE_STARTDATE,
    result: startDate
  }
};

export const updateEndDate = (endDate) => {
  return {
    type: OPEN_JOB_POSTED_DATE_ENDDATE,
    result: endDate
  }
};

export const getOpenJobsBanner = () => ({
  types: [
    GET_OPEN_JOBS_BANNER_REQUEST,
    GET_OPEN_JOBS_BANNER_SUCCESS,
    GET_OPEN_JOBS_BANNER_FAILURE,
  ],
  promise: client => client.get("get_open_jobs_banner", {}),
});

export const getOpenJobsCategories = () => ({
  types: [
    GET_OPEN_JOBS_CATEGORIES_REQUEST,
    GET_OPEN_JOBS_CATEGORIES_SUCCESS,
    GET_OPEN_JOBS_CATEGORIES_FAILURE,
  ],
  promise: client => client.post("get_open_jobs_categories", {}),
});

export const getJobsCategories = () => ({
  types: [
    GET_JOBS_CATEGORIES_REQUEST,
    GET_JOBS_CATEGORIES_SUCCESS,
    GET_JOBS_CATEGORIES_FAILURE,
  ],
  promise: client => client.get("get_jobs_categories", {}),
});

export const getOpenJobsResources = () => ({
  types: [
    GET_OPEN_JOBS_RESOURCES_REQUEST,
    GET_OPEN_JOBS_RESOURCES_SUCCESS,
    GET_OPEN_JOBS_RESOURCES_FAILURE,
  ],
  promise: client => client.get("get_open_jobs_resources", {}),
});
