import { IOpenJobsSelectedFilterOptions } from "./openJobs.interfaces";

export const OPEN_JOBS_EXPANDED_FILTER_ACCORDION = {
  jobLocation: 0,
  jobPostedDate: 1,
  jobType: 2,
};

export const OPEN_JOBS_SELECTED_FILTERS_INITIAL_STATE: IOpenJobsSelectedFilterOptions = {
  jobType: [], jobLocations: [], dateRange: null
};

export const RANGES = [
  { max: 50, label: '0 - 50 lakhs' },
  { max: 100, label: '50 lakhs - 1 Cr' },
  { max: 500, label: '1 Cr - 5 Cr' },
  { max: 1000, label: '5 Cr - 10 Cr' },
  { max: 2000, label: '10 Cr - 20 Cr' },
  { max: 3000, label: '20 Cr - 30 Cr' },
  { max: 4000, label: '30 Cr - 40 Cr' },
  { max: 5000, label: '40 Cr - 50 Cr' },
  { max: 6000, label: '50 Cr - 60 Cr' },
  { max: 20000, label: '100 Cr - 200 Cr' },
  { max: 30000, label: '200 Cr - 300 Cr' },
  { max: 40000, label: '300 Cr - 400 Cr' },
  { max: 50000, label: '400 Cr - 500 Cr' },
  { max: Infinity, label: '> 500 Cr' }
];
