import styled, { keyframes } from "styled-components";
import { Button } from "antd";
import { Box, Card, Flex, StylesProps } from "venwiz-ui";
import { BoxProps } from "venwiz-ui/dist/cjs/styled-system/primitives/Box";

export const Title = styled.div`
  color: #011759;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const Container = styled(Flex)`
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const EmptyCardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const ImgWrapper = styled.img`
  margin-bottom: 14px;
`;
export const CreateButton = styled(Button)`
  width: 240px;
  height: 40px;
  background: #215ec9;
  border-radius: 100px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  &.ant-btn:hover,
  &.ant-btn:focus {
    border: 2px solid #215ec9;
    color: #ffffff;
    background-color: #215ec9;
  }
`;

export const HeadContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0%;
`;
export const FilterContainer = styled.div`
  width: 390px;
  margin: 20px 0;
  display: flex;
  @media screen and (min-width: 1920px) {
    width: 420px;
  }
`;
export const FilterIcon = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 40px;
  background: #215ec9;
  border-radius: 8px;
  cursor: pointer;
`;

export const FilterLines = styled.div`
  border: 1.5px solid #ffff;
  width: 19.5px;
  margin-bottom: 3.5px;
  background: #fff;
  border-radius: 8px;
`;

export const FilterHeader = styled.div`
  margin: 10px 0;
  color: #215ec9;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;
export const FilterName = styled.div`
  margin: 10px 0;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: #011759;
`;
export const ResetFilter = styled.div`
  margin-top: 30px;
  color: #215ec9;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.01em;
  cursor: pointer;
`;
export const ListingCard = styled(Card)`
  align-items: center;
  border-radius: 4px;
  margin: 0 0 6px 0;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid rgba(212, 229, 254, 1);
  }
  //add a banner in the left corner of the card if isRelevant is true
  ${(props) =>
    props?.isRelevant &&
    `{
    position: relative;
    &:before {
      content: "Relevant Job";
      position: absolute;
      top: 0;
      left: 0;
      padding-left: 16px;
      padding-right: 16px;
      height: 16px;
      font-size: 12px;
      background: linear-gradient(90deg, #215EC9 0%, #80B5F2 100%);
      border-radius: 0px 0px 12px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }`}
`;
export const CardContainer = styled(Flex)`
  flex-direction: column;
`;
export const CardDetailsDiv = styled(Box)`
  font-style: normal;
  line-height: 170%;
  /* identical to box height, or 24px */
  letter-spacing: 0.01em;
  white-space: nowrap;
  padding-right: 6px;
`;

export const RequestData = styled(Box)`
  // padding-right: 5px;
  ${(props) =>
    props?.$truncate &&
    `{
    display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}`}
`;
export const ImgDesc = styled.div`
  margin-left: 25px;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;

  color: #011759;

  opacity: 0.7;
`;
export const PaginationDiv = styled(Box)`
  background-color: #ffffff;
`;
export const CardWrapper = styled(Box)`
  overflow-y: scroll;
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #000000;
`;

export const RequestListHeaderContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  cursor: pointer;
  user-select: none;
`;

export const ImgArrow = styled.img``;

export const PricingDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000000;
  letter-spacing: 0.1em;
  img {
    margin-right: 8px;
    height: 15px;
  }
  button {
    border-radius: 100px;
    margin: 8px 0 0;
    width: 190px;
    letter-spacing: 0.1em;
    height: 28px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
  }
`;

export const MonetizationTagStyles = {
  container: {
    width: "max-content",
    height: "35px",
    borderRadius: "4px",
    alignItems: "center",
    justifyContent: "center",
    letterSpacing: 0,
    gap: "8px",
    fontWeight: 400,
  } as StylesProps,
  divider: {
    width: "1.5px",
    height: "12px",
    color: "#011759",
    border: "none",
  } as StylesProps,
};

export const StepsOfJobsContainer = styled(Flex)`
  border: 3px solid #F3F6FF;
  border-radius: 10px;

  ::-webkit-scrollbar {
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f4f6ff;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb {
    height: 8px;
    background: #d5d9ec;
    border-radius: 10px;
  }
`;

export const JobCardBox = styled(Box)`
  :hover {
    border: 1px solid #215ec999;
    background: #215ec90f;
  }
`;

export const OPEN_JOBS_SEARCH_AND_FILTER_CONTAINER_HEIGHT = "60px";
